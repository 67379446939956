import axios from "axios";
import API from "../../../config";
import { retrieveAuthToken } from "../../authentication";

const token = retrieveAuthToken();

const createparcel = {
  addparcel: async (parcelData) => {
    try {
      const response = await axios.post(
        `${API}/employee/addparcel`,
        parcelData,
        {
          headers: {
            Authorization: token,
            schoolId: process.env.REACT_APP_SCHOOL_ID,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log("Error adding parcel:", error);
      throw error;
    }
  },
};

export default createparcel;
