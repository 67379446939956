import React, { useEffect, useState } from "react";
import Footer from "../../footer/Footer";
import Sidenav from "../../../Pages/nav/Sidenav";
import WOW from "wowjs";
import fetchTotal from "../../../api/fetchTotal";
import Staff from "../../../api/staff";
import Topnav from "../../../Pages/nav/Topnav";
import '../../../css/dash.css';

function Dashboard() {
  const [totalvalue, settotalvalue] = useState({});

  const [teachingStaffs, setTeachingStaffs] = useState([]);
  const [nonTeachingStaffs, setNonTeachingStaffs] = useState([]);


  const fetchtotalvalue = async () => {
    try {
      const data = await fetchTotal();
      settotalvalue({
        teachers: data.find((item) => item.table_name === "Employees (Teaching)")?.total_count || 0,
        officeStaff: data.find((item) => item.table_name === "Employees (Non-Teaching)")?.total_count || 0,
        classes: data.find((item) => item.table_name === "Classroom")?.total_count || 0,
        students: data.find((item) => item.table_name === "Students")?.total_count || 0,
        administrators: data.find((item) => item.table_name === "Admin")?.total_count || 0,
        transferCertificate: data.find((item) => item.table_name === "Transfer Certificates")?.total_count || 0,
      });
    } catch (error) {
      console.error("Error fetching total value:", error);
    }
  };


  const getTeachingStaffList = async () => {
    try {
      const data = await Staff.getTeachingStaffs(); // Correct function call
      setTeachingStaffs(data);
    } catch (error) {
      console.error("Error getting teaching staffs list:", error);
    }
  };

  const getNonTeachingStaffList = async () => {
    try {
      const data = await Staff.getNonTeachingStaffs(); // Correct function call
      setNonTeachingStaffs(data);
    } catch (error) {
      console.error("Error getting teaching staffs list:", error);
    }
  };

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    fetchtotalvalue();
    getTeachingStaffList();
    getNonTeachingStaffList();
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setSidebarVisible(false);
    } else {
      setSidebarVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Set the initial state based on the current window size

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="nav-fixed">
      <Topnav toggleSidebar={toggleSidebar} />
      <div>
        <div id="layoutSidenav" className={isSidebarVisible ? '' : 'collapsed'}>
          <div id="layoutSidenav_nav">
            {isSidebarVisible && <Sidenav />}
          </div>
          <div id="layoutSidenav_content" className={isSidebarVisible ? 'contentWithSidebar' : 'contentFullWidth'}>
            <main>
              <div className="container-fluid px-4 mb-5">
                <h1 className="mt-4">Dashboard</h1>
                {/* Rest of your content */}

                <div className="row mt-5">
                  <div className="col-xl-3 col-md-6 wow fadeInRight">
                    <div className="iconbox color1">
                      <div className="iconval">
                        <i className="fas fa-chalkboard-user"></i>
                      </div>
                    </div>
                    <div className="card up40 bg-white mb-4">
                      <div className="card-body">
                        <div className="text-end">
                          <h2>{totalvalue.teachers}</h2>Teaching Staffs
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        {/* <a className="small  stretched-link" href="/dashboard">
                          View Details
                        </a> */}
                        <div className="small">
                          {/* <i className="fas fa-angle-right"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 wow fadeInRight">
                    <div className="iconbox color2">
                      <div className="iconval">
                        <i className="fas fa-user-tie"></i>
                      </div>
                    </div>
                    <div className="card up40 bg-white  mb-4">
                      <div className="card-body">
                        <div className="text-end">
                          <h2>{totalvalue.officeStaff}</h2>Non Teaching Staffs
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        {/* <a className="small  stretched-link" href="/dashboard">
                          View Details
                        </a> */}
                        <div className="small ">
                          {/* <i className="fas fa-angle-right"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 wow fadeInRight">
                    <div className="iconbox color3">
                      <div className="iconval">
                        <i className="fas fa-chalkboard"></i>
                      </div>
                    </div>
                    <div className="card up40 bg-white  mb-4">
                      <div className="card-body">
                        <div className="text-end">
                          <h2>{totalvalue.classes}</h2>
                          Classrooms
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        {/* <a
                          className="small  stretched-link"
                          href="/section"
                        >
                          View Details
                        </a> */}
                        <div className="small ">
                          {/* <i className="fas fa-angle-right"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 wow fadeInRight">
                    <div className="iconbox color4">
                      <div className="iconval">
                        <i className="fa fa-users"></i>
                      </div>
                    </div>
                    <div className="card up40 bg-white  mb-4">
                      <div className="card-body">
                        <div className="text-end">
                          <h2>{totalvalue.students}</h2>
                          Students
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        {/* <a
                          className="small  stretched-link"
                          href="/liststudents"
                        >
                          View Details
                        </a> */}
                        <div className="small ">
                          {/* <i className="fas fa-angle-right"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 wow fadeInRight">
                    <div className="iconbox color5">
                      <div className="iconval">
                        <i className="fas fa-share-from-square"></i>
                      </div>
                    </div>
                    <div className="card up40 bg-white  mb-4">
                      <div className="card-body">
                        <div className="text-end">
                          <h2>0</h2>
                          Request
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        {/* <a
                          className="small  stretched-link"
                          href="studentdetails.php"
                        >
                          View Details
                        </a> */}
                        <div className="small ">
                          {/* <i className="fas fa-angle-right"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 wow fadeInRight">
                    <div className="iconbox colora">
                      <div className="iconval">
                        <i className="fas fa-section"></i>
                      </div>
                    </div>
                    <div className="card up40 bg-white  mb-4">
                      <div className="card-body">
                        <div className="text-end">
                          <h2>0</h2>
                          Sections
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        {/* <a
                          className="small  stretched-link"
                          href="studentdetails.php"
                        >
                          View Details
                        </a> */}
                        <div className="small ">
                          {/* <i className="fas fa-angle-right"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 wow fadeInRight">
                    <div className="iconbox colorb">
                      <div className="iconval">
                        <i className="fas fa-certificate"></i>
                      </div>
                    </div>
                    <div className="card up40 bg-white  mb-4">
                      <div className="card-body">
                        <div className="text-end">
                          <h2>{totalvalue.transferCertificate}</h2>
                          Transfer Certificates
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        {/* <a
                          className="small  stretched-link"
                          href="studentdetails.php"
                        >
                          View Details
                        </a> */}
                        <div className="small ">
                          {/* <i className="fas fa-angle-right"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 wow fadeInRight">
                    <div className="iconbox colorc">
                      <div className="iconval">
                        <i className="fas fa-envelope"></i>
                      </div>
                    </div>
                    <div className="card up40 bg-white  mb-4">
                      <div className="card-body">
                        <div className="text-end">
                          <h2>0</h2>
                          Message
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        {/* <a
                          className="small  stretched-link"
                          href="studentdetails.php"
                        >
                          View Details
                        </a> */}
                        <div className="small ">
                          {/* <i className="fas fa-angle-right"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <section className="wow fadeInUp">
                  <div className="row mt-5 mb-5">
                    <div className="iconbox color2 up10">
                      <div className="iconval wow shake">
                        <i className="fas fa-chalkboard-user"></i>
                      </div>
                    </div>
                    <div className="heading">
                      <h4>Teachers Details</h4>
                    </div>
                  </div>
                  <div className="row">
                    <table
                      id=""
                      className="display  table-hover"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Employee Number</th>
                          <th>Name</th>
                          <th>Position</th>
                          <th>Phone</th>
                          <th>Job Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {teachingStaffs.map((staff) => (
                          <tr key={staff.id}>
                            <td>{staff.employee_num}</td>
                            <td>{staff.name}</td>
                            <td>{staff.designation}</td>
                            <td>{staff.phone}</td>
                            <td>{staff.job_type}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Employee Number</th>
                          <th>Name</th>
                          <th>Position</th>
                          <th>Phone</th>
                          <th>Job Type</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </section>

                <section className="wow fadeIn">
                  <div className="row mt-5 mb-5">
                    <div className="iconbox color2 up10">
                      <div className="iconval wow shake">
                        <i className="fas fa-user-tie"></i>
                      </div>
                    </div>
                    <div className="heading">
                      <h4> Staff Details</h4>
                    </div>
                  </div>
                  <table id="" className="display" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Employee Number</th>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Phone</th>
                        <th>Job Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nonTeachingStaffs.map((staff) => (
                        <tr key={staff.id}>
                          <td>{staff.employee_num}</td>
                          <td>{staff.name}</td>
                          <td>{staff.designation}</td>
                          <td>{staff.phone}</td>
                          <td>{staff.job_type}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Employee Number</th>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Phone</th>
                        <th>Job Type</th>
                      </tr>
                    </tfoot>
                  </table>
                </section>
              </div>
              <Footer />
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
