import axios from "axios";
import API from "../config/index";
import { retrieveAuthToken } from "./authentication";

const fetchTotal = async () => {
  const token = retrieveAuthToken();
  try {
    const response = await axios.get(`${API}/total/`, {
      headers: {
        Authorization: token,
        schoolId: process.env.REACT_APP_SCHOOL_ID,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching total data:", error);
    throw error;
  }
};

export default fetchTotal;
