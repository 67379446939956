import React from "react";
import "./login.css";
import Input from "./Input";
function Login() {
  return (
    <body className="color9 backgroud" >
      <div className="container ">
        <div className="row text-center container   d-flex justify-content-center align-items-center"  >

          <div className="col-12 col-lg-4  " style={{ width: '42%', borderRadius: '2%' }} >
            <section className="pt-4 pb-5 ">
              <div className="mb-5">
                <div className="logosetdark"></div>
              </div>
              <div className="d-flex justify-content-center">
                <ul
                  className="nav nav-tabs  justify-content-center "
                  id="myTab"
                  role="tablist"
                  style={{ width: '95%' }}
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="user-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#user-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="user-tab-pane"
                      aria-selected="true"
                    >
                      user Login
                    </button>
                  </li>
                </ul>
              </div>
              <Input />
              <div className="col-12 mt-2">Copyright &copy; WHITE LMS 2023</div>
            </section>
          </div>

        </div>
      </div>
      <script src="assets/js/script.js"></script>
    </body>
  );
}

export default Login;
