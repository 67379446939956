import React, { useEffect, useState } from "react";
import Footer from "../../footer/Footer";
import WOW from "wowjs";
import Sidenav from "../../../Pages/nav/Sidenav";
import Topnav from "../../../Pages/nav/Topnav";
import axios from "axios";
import API from "../../../config"; // Adjust the import path based on your project structure
import { retrieveAuthToken } from "../../../api/authentication"; // Adjust the import path

function Admin() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    password: "",
    email: "",
    mobile: "",
  });
  const [adminList, setAdminList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editAdmin, setEditAdmin] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = retrieveAuthToken();

    try {
      setLoading(true);
      // const responses = await fetch("https://geolocation-db.com/json/");
      // const data = await responses.json();
      // const ip = data.IPv4;
      const ip = "192.168.1.1"; // Placeholder IP address for demonstration purposes
      const last_login = new Date();
      const response = await axios.post(
        `${API}/admin/addAdmin`,
        {
          name: formData.name,
          email: formData.email,
          phone: formData.mobile,
          password: formData.password,
          role: "admin",
          username: formData.username,
          last_login: "",
          last_login_ip: ip,
        },
        {
          headers: {
            Authorization: token,
            schoolId: process.env.REACT_APP_SCHOOL_ID,
            "Content-Type": "application/json",
          },
        }
      );

      // Fetch updated admin list after successful creation
      const updatedAdminList = await axios.get(`${API}/admin/`, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
          "Content-Type": "application/json",
        },
      });

      setAdminList(updatedAdminList.data); // Update adminList state with new data
      setFormData({
        name: "",
        username: "",
        password: "",
        email: "",
        mobile: "",
      });
      setError("");
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to create admin. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e, adminId) => {
    e.preventDefault();
    const token = retrieveAuthToken();
    if (!adminId) {
      alert("Admin ID is undefined. Cannot delete admin.");
      return;
    }
    try {
      setLoading(true);
      await axios.delete(`${API}/admin/${adminId}`, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
          "Content-Type": "application/json",
        },
      });
      setAdminList((prevList) =>
        prevList.filter((admin) => admin.id !== adminId)
      );
      setError("");
    } catch (error) {
      console.error("Error deleting admin:", error);
      setError("Failed to delete admin. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (e, admin) => {
    e.preventDefault();
    setEditAdmin({ ...admin });
    setIsEditMode(true);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditAdmin({ ...editAdmin, [name]: value });
  };

  const handleSave = async (e, admin) => {
    e.preventDefault();
    const token = retrieveAuthToken();

    try {
      setLoading(true);
      const response = await axios.put(
        `${API}/admin/${editAdmin.adminId}`,
        {
          username: editAdmin.name,
          email: editAdmin.email,
          phone: editAdmin.phone,
        },
        {
          headers: {
            Authorization: token,
            schoolId: process.env.REACT_APP_SCHOOL_ID,
            "Content-Type": "application/json",
          },
        }
      );

      // After successful update, fetch updated admin details
      const updatedAdminDetails = await axios.get(
        `${API}/admin/info/${editAdmin.adminId}`,
        {
          headers: {
            Authorization: token,
            schoolId: process.env.REACT_APP_SCHOOL_ID,
            "Content-Type": "application/json",
          },
        }
      );

      // Update adminList with the updated admin details
      setAdminList((prevAdminList) =>
        prevAdminList.map((admin) =>
          admin.adminId === editAdmin.adminId ? updatedAdminDetails.data : admin
        )
      );

      setIsEditMode(false);
      setEditAdmin(null);
      setError("");
    } catch (error) {
      console.error("Error updating admin:", error);
      setError("Failed to update admin. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    setIsEditMode(false);
    setEditAdmin(null);
  };

  useEffect(() => {
    const fetchAdminDetails = async () => {
      const token = retrieveAuthToken();

      try {
        setLoading(true);
        const response = await axios.get(`${API}/admin/`, {
          headers: {
            Authorization: token,
            schoolId: process.env.REACT_APP_SCHOOL_ID,
            "Content-Type": "application/json",
          },
        });
        setAdminList(response.data);
        setError("");
      } catch (error) {
        console.error("Error fetching admin details:", error);
        setError("Failed to fetch admin details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchAdminDetails();
  }, []);

  return (
    <div className="nav-fixed">
      <Topnav toggleSidebar={toggleSidebar} />
      <div id="layoutSidenav">
        {isSidebarVisible && (
          <div>
            <Sidenav />
          </div>
        )}
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid">
                <h1 className="mt-4">Admin Details</h1>
                {loading && <p>Loading...</p>}
                {error && <p className="text-danger">{error}</p>}
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <section className="wow fadeInLeft">
                      <div className="row">
                        <form className="row g-3" onSubmit={handleSubmit}>
                          <h5>
                            <u>Enter Admin Details</u>
                          </h5>
                          <div className="col-auto">
                            <label className="form-label">
                              Full name <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-auto">
                            <label className="form-label">
                              Username <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="username"
                              value={formData.username}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-auto">
                            <label className="form-label">
                              Password <span>*</span>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              value={formData.password}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-auto">
                            <label className="form-label">
                              Email Id <span>*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-auto">
                            <label className="form-label">
                              Mobile Number <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="mobile"
                              value={formData.mobile}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-auto">
                            <button
                              className="btn btn-theme2"
                              type="submit"
                              disabled={loading}
                            >
                              Create Admin
                            </button>
                          </div>
                        </form>
                      </div>
                    </section>
                  </div>
                  <div className="col-12 col-md-6 col-lg-8">
                    <section className="wow fadeInLeft">
                      <div className="row mt-5">
                        <div className="iconbox color2 up10">
                          <div className="iconval wow shake">
                            <i className="fa-solid fa-user-shield"></i>
                          </div>
                        </div>
                        <div className="heading">
                          <h4>Admin Details</h4>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <table
                          className="display responsive nowrap"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Contact</th>
                              <th>Email</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {adminList.map((admin) => (
                              <tr key={admin.id}>
                                <td>
                                  {isEditMode && editAdmin.id === admin.id ? (
                                    <input
                                      type="text"
                                      name="username"
                                      value={editAdmin.username}
                                      onChange={handleEditInputChange}
                                    />
                                  ) : (
                                    admin.username // Display username as the name
                                  )}
                                </td>
                                <td>
                                  {isEditMode && editAdmin.id === admin.id ? (
                                    <input
                                      type="text"
                                      name="phone"
                                      value={editAdmin.phone}
                                      onChange={handleEditInputChange}
                                    />
                                  ) : (
                                    admin.phone
                                  )}
                                </td>
                                <td>
                                  {isEditMode && editAdmin.id === admin.id ? (
                                    <input
                                      type="email"
                                      name="email"
                                      value={editAdmin.email}
                                      onChange={handleEditInputChange}
                                    />
                                  ) : (
                                    admin.email
                                  )}
                                </td>
                                <td>{admin.status}</td>
                                <td>
                                  {isEditMode && editAdmin.id === admin.id ? (
                                    <>
                                      <button
                                        className="btn btn-theme2"
                                        onClick={(e) => handleSave(e, admin)}
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="btn btn-theme2"
                                        onClick={handleCancelEdit}
                                      >
                                        Cancel
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      {/* <a
                                        href=" "
                                        className="tablebutton bg-primary text-white"
                                        onClick={(e) => handleEdit(e, admin)}
                                      >
                                        <i className="fas fa-edit"></i>
                                      </a> */}
                                      <a
                                        href=" "
                                        className="tablebutton bg-danger text-white mx-2"
                                        onClick={(e) =>
                                          handleDelete(e, admin.id)
                                        }
                                      >
                                        <i className="fas fa-trash"></i>
                                      </a>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
