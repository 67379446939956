import React from "react";
import "./colorlib-error-404-3/colorlib-error-404-3/css/style.css";
import Topnav from "./Components/Pages/nav/Topnav";
import Sidenav from "./Components/Pages/nav/Sidenav";

function Underwork() {
  return (
    //
    <div className="nav-fixed">
      <Topnav />
      <div id="layoutSidenav">
        <div>
          <Sidenav />
        </div>

        <div id="layoutSidenav_content">
          <div>
            <main>
              <div id="notfound">
                <div class="notfound">
                  <div class="notfound-404">
                    <h3>Oops! Page not found</h3>
                    <h1>
                      <span>4</span>
                      <span>0</span>
                      <span>4</span>
                    </h1>
                  </div>
                  <h2>
                    We are Under Development - Will be updated shortly...
                  </h2>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Underwork;
