import React, { useEffect, useState } from "react";
import Footer from "../../footer/Footer";
import WOW from "wowjs";
import Sidenav from "../../../Pages/nav/Sidenav";
import Topnav from "../../../Pages/nav/Topnav";
import Staff from "../../../api/staff";

function Staffdetils() {
    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const [erpnum, setErpnum] = useState(false);
    const [formData, setFormData] = useState({
        employee_num: "",
        type: "",
        name: "",
        date_of_birth: "",
        gender: "",
        blood_group: "",
        marital_status: "",
        spouse_name: "",
        religion: "",
        caste: "",
        fathers_name: "",
        mothers_name: "",
        fathers_occupation: "",
        mothers_occupation: "",
        fathers_qualification: "",
        mothers_qualification: "",
        identification_mark_1: "",
        identification_mark_2: "",
        aadhar: "",
        profile_pic_url: null,
        phone: "",
        alternate_mobile: "",
        father_mobile: "",
        mother_mobile: "",
        father_email: "",
        mother_email: "",
        permanent_address: "",
        permanent_city: "",
        permanent_state: "",
        permanent_zip: "",
        present_address: "",
        present_city: "",
        present_state: "",
        present_zip: "",
        email: "",
        mobile_number: " ",
        alternate_number: " ",
        whatsapp_number: " ",
        year_of_experience: " ",
        completed_degree: " ",
        expertise: " ",
        passout_year: " ",
        account_number: " ",
        bank_name: " ",
        ifsc_code: " ",
        branch: " ",
        pan_number: " ",
        job_type: " ",
        designation: " ",
        joining_date: " ",
        salary_base: " ",
        base_amount: " "
    });






    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);
    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({ ...formData, [name]: files[0] });
    };

    const generateERPNumber = () => {
        const currentYear = new Date().getFullYear();
        const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");
        const randomNum = Math.floor(Math.random() * 1000) + 1;
        const num = `WLE${currentYear}SDAMDU${currentMonth}${randomNum}`;
        setErpnum(num);
        return `WLE${currentYear}SDAMDU${currentMonth}${randomNum}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const employee_num = generateERPNumber();
        const staffData = { ...formData, employee_num };

        try {
            const response = await Staff.addStaff(staffData);
            // Add popup notification
            alert("Staff created successfully!");

            // Clear form data
            setFormData({
                employee_num: "",
                type: "",
                name: "",
                date_of_birth: "",
                gender: "",
                blood_group: "",
                marital_status: "",
                spouse_name: "",
                religion: "",
                caste: "",
                fathers_name: "",
                mothers_name: "",
                fathers_occupation: "",
                mothers_occupation: "",
                fathers_qualification: "",
                mothers_qualification: "",
                identification_mark_1: "",
                identification_mark_2: "",
                aadhar: "",
                profile_pic_url: null,
                phone: "",
                alternate_mobile: "",
                father_mobile: "",
                mother_mobile: "",
                father_email: "",
                mother_email: "",
                permanent_address: "",
                permanent_city: "",
                permanent_state: "",
                permanent_zip: "",
                present_address: "",
                present_city: "",
                present_state: "",
                present_zip: "",
                email: "",
                mobile_number: " ",
                alternate_number: " ",
                whatsapp_number: " ",
                year_of_experience: " ",
                completed_degree: " ",
                expertise: " ",
                passout_year: " ",
                account_number: " ",
                bank_name: " ",
                ifsc_code: " ",
                branch: " ",
                pan_number: " ",
                job_type: " ",
                designation: " ",
                joining_date: " ",
                salary_base: " ",
                base_amount: " "
            });
        } catch (error) {
            console.error("Error creating Staff:", error);
            // Handle error (e.g., show an error message)
            alert("Error creating Staff. Please try again.");
        }
    };
    return (
        <div className="nav-fixed">
            <Topnav toggleSidebar={toggleSidebar} />
            <div id="layoutSidenav">
                {isSidebarVisible && (
                    <div>
                        <Sidenav />
                    </div>
                )}
                <div
                    id="layoutSidenav_content"
                >
                    <div >
                        <main>
                            <div className="container-fluid p-3">
                                <h1 className="mt-4 mb-2">Staff Details</h1>
                                <div className="row ms-2">
                                    <form className="row g-4 mb-5" onSubmit={handleSubmit}>
                                        <section className="wow fadeInLeft">
                                            <div className="row mb-5">
                                                <div className="iconbox color2 up10">
                                                    <div className="iconval wow shake"><i className="fa-solid fa-user-tie"></i></div>
                                                </div>
                                                <div className="heading">
                                                    <h4>Personal Information </h4>
                                                </div>
                                            </div>
                                            <div className="container">
                                                <div className="row g-4 mb-5">
                                                    <div className=" col-12">
                                                        <label className="form-label">Full Name <span>*</span></label>
                                                        <input type="text" className="form-control" name="name" value={formData.name}
                                                            onChange={handleInputChange} required />
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Date of Birth <span>*</span></label>
                                                        <input type="date" className="form-control" name="date_of_birth" value={formData.date_of_birth}
                                                            onChange={handleInputChange} required />
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Gender <span>*</span></label>
                                                        <select className="form-select" name="gender" value={formData.gender}
                                                            onChange={handleInputChange} required>
                                                            <option selected> - Select - </option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Female">Transgender</option>
                                                        </select>
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Blood Group</label>
                                                        <select className="form-select" name="blood_group">
                                                            <option value={formData.blood_group}
                                                                onChange={handleInputChange}> - Select - </option>
                                                            <option value="O+">O+</option>
                                                            <option value="O-">O-</option>
                                                            <option value="A+">A+</option>
                                                            <option value="A-">A-</option>
                                                            <option value="B+">B+</option>
                                                            <option value="B-">B-</option>
                                                            <option value="AB+">AB+</option>
                                                            <option value="AB-">AB-</option>
                                                            <option value="A1+">A1+</option>
                                                            <option value="A1-">A1-</option>
                                                        </select>
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Father's name</label>
                                                        <input type="text" className="form-control" name="fathers_name" value={formData.fathers_name}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Mother's name</label>
                                                        <input type="text" className="form-control" name="mothers_name" value={formData.mothers_name}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Marital Status <span>*</span></label>
                                                        <select className="form-select" name="marital_status" value={formData.marital_status}
                                                            onChange={handleInputChange} required>
                                                            <option selected> - Select - </option>
                                                            <option value="Married">Married</option>
                                                            <option value="Unmarried">Unmarried</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Spouse Name</label>
                                                        <input type="text" className="form-control" name="spouse_name" value={formData.spouse_name}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Identification Mark 1 <span>*</span></label>
                                                        <input type="text" className="form-control" name="identification_mark_1" value={formData.identification_mark_1}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Identification Mark 2</label>
                                                        <input type="text" className="form-control" name="identification_mark_2" value={formData.identification_mark_2}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Aadhar No <span>*</span></label>
                                                        <input type="text" className="form-control" name="aadhar" value={formData.aadhar}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Profile Picture<span>*</span></label>
                                                        <input type="file" className="form-control" name="profile_pic_url"
                                                            onChange={handleFileChange} />
                                                    </div>

                                                </div>
                                            </div>
                                        </section>

                                        <section className="wow fadeInLeft">
                                            <div className="row mb-5">
                                                <div className="iconbox color2 up10">
                                                    <div className="iconval wow shake"><i className="fa fa-address-book"></i></div>
                                                </div>
                                                <div className="heading">
                                                    <h4>Contact Information</h4>
                                                </div>
                                            </div>
                                            <div className="container">
                                                <div className="row g-4 mb-5">



                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Address(House No, Street Name)<span>*</span></label>
                                                        <input type="text" className="form-control" name="permanent_address" value={formData.permanent_address}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">City <span>*</span></label>
                                                        <input type="text" className="form-control" name="permanent_city" value={formData.permanent_city}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">State <span>*</span></label>
                                                        <input type="text" className="form-control" name="permanent_state" value={formData.permanent_state}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Zip <span>*</span></label>
                                                        <input type="text" className="form-control" name="permanent_zip" value={formData.permanent_zip}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <h6><u>Present Details</u></h6>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Address(House No, Street Name)<span>*</span></label>
                                                        <input type="text" className="form-control" name="present_address" value={formData.present_address}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">City <span>*</span></label>
                                                        <input type="text" className="form-control" name="present_city" value={formData.present_city}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">State <span>*</span></label>
                                                        <input type="text" className="form-control" name="present_state" value={formData.present_state}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Zip <span>*</span></label>
                                                        <input type="text" className="form-control" name="present_zip" value={formData.present_zip}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Email Id <span>*</span></label>
                                                        <input type="email" className="form-control" name="email" value={formData.email}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Mobile Number <span>*</span></label>
                                                        <input type="text" className="form-control" name="phone" value={formData.phone}
                                                            onChange={handleInputChange} required />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Alternate Mobile Number <span>*</span></label>
                                                        <input type="text" className="form-control" name="alternate_mobile" value={formData.alternate_mobile}
                                                            onChange={handleInputChange} required />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Whatsapp Number <span>*</span></label>
                                                        <input type="text" className="form-control" name="whatsapp_number" value={formData.whatsapp_number}
                                                            onChange={handleInputChange} required />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <section className="wow fadeInLeft">
                                            <div className="row mb-5">
                                                <div className="iconbox color2 up10">
                                                    <div className="iconval wow shake"><i className="fa-solid fa-graduation-cap"></i></div>
                                                </div>
                                                <div className="heading">
                                                    <h4>Education and Experience</h4>
                                                </div>
                                            </div>
                                            <div className="container">
                                                <div className="row g-4 mb-5">

                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label"> Year of Experience</label>
                                                        <input type="text" className="form-control" name="year_of_experience" value={formData.year_of_experience}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Completed Degree</label>
                                                        <input type="text" className="form-control" name="completed_degree" value={formData.completed_degree}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Expertise</label>
                                                        <input type="text" className="form-control" name="expertise" value={formData.expertise}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Year of Passed Out(Last Degree) </label>
                                                        <input type="text" className="form-control" name="passout_year" value={formData.passout_year}
                                                            onChange={handleInputChange} />
                                                    </div >
                                                </div>
                                            </div>
                                        </section>

                                        <section className="wow fadeInLeft">
                                            <div className="row mb-5">
                                                <div className="iconbox color2 up10">
                                                    <div className="iconval wow shake"><i className="fa-solid fa-bank"></i></div>
                                                </div>
                                                <div className="heading">
                                                    <h4>Bank Details</h4>
                                                </div>
                                            </div>
                                            <div className="container">
                                                <div className="row g-4 mb-5">

                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Account Number</label>
                                                        <input type="text" className="form-control" name="account_number" value={formData.account_number}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Confirm Account Number</label>
                                                        <input type="text" className="form-control" name="account_number" value={formData.account_number}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Bank Name</label>
                                                        <input type="text" className="form-control" name="bank_name" value={formData.bank_name}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">IFSC Code</label>
                                                        <input type="text" className="form-control" name="ifsc_code" value={formData.ifsc_code}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Branch</label>
                                                        <input type="text" className="form-control" name="branch" value={formData.branch}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Pan Number</label>
                                                        <input type="text" className="form-control" name="pan_number" value={formData.pan_number}
                                                            onChange={handleInputChange} />
                                                    </div>

                                                </div>
                                            </div>
                                        </section>

                                        <section className="wow fadeInLeft">
                                            <div className="row mb-5">
                                                <div className="iconbox color2 up10">
                                                    <div className="iconval wow shake"><i className="fa fa-id-card"></i></div>
                                                </div>
                                                <div className="heading">
                                                    <h4>Current Profile</h4>
                                                </div>
                                            </div>
                                            <div className="container">
                                                <div className="row g-4 mb-5">

                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Job Type </label>
                                                        <select className="form-select" name="job_type" value={formData.job_type}
                                                            onChange={handleInputChange}>
                                                            <option selected> - Select - </option>
                                                            <option value="Permanent">Permanent</option>
                                                            <option value="Temporary">Temporary</option>
                                                            <option value="Contract">Contract</option>
                                                            <option value="Training">Training</option>
                                                            <option value="Part Time">Part Time</option>

                                                        </select>
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Designation
                                                        </label>
                                                        <select className="form-select" name="type" value={formData.type}
                                                            onChange={handleInputChange}>
                                                            <option selected> - Select - </option>
                                                            <option value="TS">Teaching Staff</option>
                                                            <option value="MS">Management Staff</option>
                                                            <option value="TMS">Teaching + Management</option>
                                                            <option value="NTS">Non Teaching Staff</option>
                                                            <option value="HK">House Keeping</option>
                                                            <option value="D">Driver</option>
                                                            <option value="S">Security</option>

                                                        </select>
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label"> Employee Id </label>
                                                        <input type="text" className="form-control" name="erpnum" value={erpnum}
                                                            onChange={handleInputChange} readOnly />
                                                    </div>
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Joining Date </label>
                                                        <input type="date" className="form-control" name="joining_date" value={formData.joining_date}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                    {/* <div className=" col-12 col-md-6">
                                                        <label className="form-label">Salary Base </label>
                                                        <select className="form-select" name="salarybase">
                                                            <option selected> - Select - </option>
                                                            <option value="Base 1">Base 1</option>
                                                            <option value="Base 2">Base 2</option>
                                                        </select>
                                                    </div> */}
                                                    <div className=" col-12 col-md-6">
                                                        <label className="form-label">Base Amount </label>
                                                        <input type="text" className="form-control" name="base_amount" value={formData.base_amount}
                                                            onChange={handleInputChange} />
                                                    </div>

                                                    {/* <div className=" col-12 col-md-6">
                                                        <label className="form-label">Username <span>*</span></label>
                                                        <input type="text" className="form-control" name="username" value={formData}
                              onChange={handleInputChange} required />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label">Password <span>*</span></label>
                                                        <input type="password" className="form-control" name="password" value={formData}
                              onChange={handleInputChange} required />
                                                    </div> */}
                                                    <div className="col-12">
                                                        <button className="btn btn-theme2" type="submit">Create Staff Profile</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </form>
                                </div>
                            </div>
                        </main>
                        <Footer />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Staffdetils;