import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidenav from "../../../Pages/nav/Sidenav";
import Footer from "../../footer/Footer";
import Topnav from "../../../Pages/nav/Topnav";
import API from "../../../config/index";
import { retrieveAuthToken } from "../../../api/authentication"

function Announcement() {
  const [classrooms, setClassrooms] = useState([]);
  const token = retrieveAuthToken();

  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const response = await axios.get(`${API}/classroom/`, {
          headers: {
            Authorization: token,
            schoolId: process.env.REACT_APP_SCHOOL_ID,
          },
        });

        setClassrooms(response.data);
      } catch (error) {
        console.error("Error fetching classroom data:", error);
      }
    };

    fetchClassrooms();
  }, [token]);

  return (
    <div className="nav-fixed">
      <Topnav />
      <div id="layoutSidenav">
        <div>
          <Sidenav />
        </div>
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid p-3">
                <h1 className="mt-4 mb-2">Create Announcement</h1>
                <section>
                  <div className="row mt-5">
                    <div className="iconbox color2 up10">
                      <div className="iconval wow shake">
                        <i className="fa-solid fa-bullhorn"></i>
                      </div>
                    </div>
                    <div className="heading">
                      <h4>Create Announcement</h4>
                    </div>
                  </div>
                  <div className="row">
                    <form className="row g-4">
                      <div className="col-12">
                        <label className="form-label">Announcement To</label>
                        <select className="form-select" name="announcementTo" required>
                          <option value="" selected> - Select - </option>
                          <option value="students">Students</option>
                          <option value="teachingstaff">Teaching Staff</option>
                          <option value="all">All</option>
                        </select>
                      </div>
                      <div className="col-12">
                        <label className="form-label">Announcement Type</label>
                        <select className="form-select" name="announcementType" required>
                          <option value="" selected> - Select - </option>
                          <option value="leaveannouncement">Leave Announcement</option>
                          <option value="examannouncement">Exam Announcement</option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label">
                          Title<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          required
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label">Class</label>
                        <select className="form-select" name="class" required>
                          <option value="" selected> - Select - </option>
                          {classrooms.map((classroom) => (
                            <option key={classroom.id} value={classroom.id}>
                              {classroom.class} {classroom.section}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12">
                        <label className="form-label">Upload Document</label>
                        <input type="file"
                          className="form-control"
                          name="announcement"
                          rows="3"
                        ></input>
                      </div>
                      <div className="col-12">
                        <label className="form-label">Announcement</label>
                        <textarea
                          className="form-control"
                          name="announcement"
                          rows="3"
                        ></textarea>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-theme2" type="submit">
                          Send Announcement
                        </button>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Announcement;
