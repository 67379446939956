import axios from "axios";
import API from "../../../config";
import { retrieveAuthToken } from "../../authentication";

const token = retrieveAuthToken();

const createGate = {
  addGatepass: async (gatepassData) => {
    try {
      const response = await axios.post(`${API}/gatepass`, gatepassData, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error adding gatepass:", error);
      throw error;
    }
  },
  getGatepass: async (gatepassData) => {
    try {
      const response = await axios.get(`${API}/gatepass/`, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.log("Error getting gatepass:", error);
      throw error;
    }
  },
  // getGatepass: async (classId) => {
  //   try {
  //     const response = await axios.get(`${API}/gatepass`, {
  //       headers: {
  //         Authorization: token,
  //       },
  //     });
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error fetching classroom data:", error);
  //     throw error;
  //   }
  // },
};

export default createGate;
