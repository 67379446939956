import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Test from "../Test";
import Resetpassword from "../Pages/Resetpassword/Resetpassword";
import Login from "../Pages/Login/Login";
import Dashboard from "../views/Core/Dashboard/Dashboard";
import Viewprofile from "../Pages/Viewprofile/Viewprofile";
import Admin from "../views/Core/Admin/Admin";

import Studentdetails from "../views/Users/student/Studentdetails";
import Studentprofile from "../views/Users/student/Studentprofile";
import Studentattendance from "../views/Users/student/Studentattendance";
import Absentees from "../views/Users/student/ViewAttendance";
import ListStudents from "../views/Users/student/ListStudents";
import HomeworkAnnouncements from "../views/Announcements/HomeworkAnnouncments";
import ExamAnnouncements from "../views/Announcements/ExamAnnouncements";


import Staffdetails from "../views/Users/staff/Staffdetails";
import Staffprofile from "../views/Users/staff/Staffprofile";
import Staffattendance from "../views/Users/staff/Staffattendance";
import Staffrequest from "../views/Users/staff/Staffrequest";

import Section from "../views/Class/Section";
import Subject from "../views/Class/Subject";
import Classallocation from "../views/Class/Classallocation";
import Timetable from "../views/Class/Timetable";
import Setupexam from "../views/Class/Setupexam";

import Feeplan from "../views/Payments/fees/Feeplan";
import Feestructure from "../views/Payments/fees/Feestructure";
import Feeconcession from "../views/Payments/fees/Feeconcession";
import Feemode from "../views/Payments/fees/Feemode";

import Payrollbase from "../views/Payments/payroll/Payrollbase";
import Payrolldetails from "../views/Payments/payroll/Payrolldetails";

import Createtc from "../views/Administrations/tc/Createtc";
import Managetc from "../views/Administrations/tc/Managetc";

import Visitors from "../views/Administrations/visitors/Visitors";
import Parcel from "../views/Administrations/visitors/Parcel";
import Gatepass from "../views/Administrations/visitors/Gatepass";

import Announcement from "../views/Administrations/communication/Announcement";
import Sendmessage from "../views/Administrations/communication/Sendmessage";


import Medicalcondition from "../views/Others/Medicalcondition/Medicalconditon";
import BulkUpload from "../views/Others/BulkUpload/BulkUpload";

import Underwork from "../../underwork";

function Routing() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/viewprofile" element={<Viewprofile />} />
        <Route path="/resetpassword" element={<Resetpassword />} />
        <Route path="/logout" element={<Login />} />
        <Route path="/staffdetails" element={<Staffdetails />} />
        <Route path="/staffprofile" element={<Staffprofile />} />
        <Route path="/staffattendance" element={<Underwork />} />
        <Route path="/staffrequest" element={<Underwork />} />
        <Route path="/studentdetails" element={<Studentdetails />} />
        <Route path="/studentprofile" element={<Studentprofile />} />
        <Route path="/studentattendance" element={<Studentattendance />} />
        <Route path="/viewattendance" element={<Absentees />} />
        <Route path="/liststudents" element={<ListStudents />} />
        <Route path="/homeworkannouncement" element={<HomeworkAnnouncements />} />
        <Route path="/examannouncement" element={<ExamAnnouncements />} />
        <Route path="/section" element={<Section />} />
        <Route path="/subject" element={<Subject />} />
        <Route path="/classallocation" element={<Classallocation />} />
        <Route path="/timetable" element={<Timetable />} />
        <Route path="/setupexam" element={<Underwork />} />
        <Route path="/feeplan" element={<Underwork />} />
        <Route path="/feestructure" element={<Underwork />} />
        <Route path="/feeconcession" element={<Underwork />} />
        <Route path="/feemode" element={<Underwork />} />
        <Route path="/payrollbase" element={<Underwork />} />
        <Route path="/payrolldetails" element={<Underwork />} />
        <Route path="/createtc" element={<Createtc />} />
        <Route path="/managetc" element={<Underwork />} />
        <Route path="/visitors" element={<Visitors />} />
        <Route path="/parcel" element={<Parcel />} />
        <Route path="/gatepass" element={<Underwork />} />
        <Route path="/announcement" element={<Announcement />} />
        <Route path="/sendmessage" element={<Underwork />} />
        <Route path="/medicalcondition" element={<Underwork />} />
        <Route path="/bulkUpload" element={<BulkUpload />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </Router>
  );
}

export default Routing;
