import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Sidenav from "../../Pages/nav/Sidenav";
import WOW from "wowjs";
import Topnav from "../../Pages/nav/Topnav";
import Staff from "../../api/staff"; // Make sure the import path is correct
import Classroom from "../../api/classroom"; // Make sure the import path is correct

function Section() {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [staffs, setStaffs] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [formData, setFormData] = useState({
    class_name: "",
    section: "",
    incharge_id: "",
  });

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();

    const fetchTeachingStaffs = async () => {
      try {
        const staffData = await Staff.getTeachingStaffs();
        setStaffs(staffData);
      } catch (error) {
        console.error("Error fetching teaching staffs:", error);
      }
    };

    const fetchClassrooms = async () => {
      try {
        const classroomData = await Classroom.getClassroom();
        setClassrooms(classroomData);
      } catch (error) {
        console.error("Error fetching classroom data:", error);
      }
    };

    fetchTeachingStaffs();
    fetchClassrooms();
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await Classroom.createClassroom(formData);
      const updatedClassrooms = await Classroom.getClassroom();
      setClassrooms(updatedClassrooms);
      setFormData({
        class_name: "",
        section: "",
        incharge_id: "",
      });
    } catch (error) {
      console.error("Error creating classroom:", error);
    }
  };

  return (
    <div className="nav-fixed">
      <Topnav toggleSidebar={toggleSidebar} />
      <div id="layoutSidenav">
        {isSidebarVisible && (
          <div>
            <Sidenav />
          </div>
        )}

        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid p-3">
                <h1 className="mt-4 mb-2">Section Details</h1>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <section>
                      <div className="row">
                        <form className="row g-3" onSubmit={handleSubmit}>
                          <h5>
                            <u>Enter Section Details</u>
                          </h5>
                          <div className="col-12">
                            <label className="form-label">Class</label>
                            <select
                              className="form-select"
                              name="class_name"
                              value={formData.class_name}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="" disabled>
                                - Select -
                              </option>
                              <option value="PreKG">PreKG</option>
                              <option value="LKG">LKG</option>
                              <option value="UKG">UKG</option>
                              <option value="1">I</option>
                              <option value="2">II</option>
                              <option value="3">III</option>
                              <option value="4">IV</option>
                              <option value="5">V</option>
                              <option value="6">VI</option>
                              <option value="7">VII</option>
                              <option value="8">VIII</option>
                              <option value="9">IX</option>
                              <option value="10">X</option>
                              <option value="11">XI</option>
                              <option value="12">XII</option>
                            </select>
                          </div>

                          <div className="col-12">
                            <label className="form-label">Section</label>
                            <select
                              className="form-select"
                              name="section"
                              value={formData.section}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="" disabled selected>
                                - Select -
                              </option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                              <option value="F">F</option>
                              <option value="G">G</option>
                              <option value="H">H</option>
                              <option value="I">I</option>
                              <option value="J">J</option>
                              <option value="K">K</option>
                              <option value="L">L</option>
                              <option value="M">M</option>
                              <option value="N">N</option>
                              <option value="O">O</option>
                              <option value="P">P</option>
                              <option value="Q">Q</option>
                              <option value="R">R</option>
                              <option value="S">S</option>
                              <option value="T">T</option>
                              <option value="U">U</option>
                              <option value="V">V</option>
                              <option value="W">W</option>
                              <option value="X">X</option>
                              <option value="Y">Y</option>
                              <option value="Z">Z</option>
                              <option value="A1">A1</option>
                              <option value="A2">A2</option>
                              <option value="A3">A3</option>
                              <option value="A4">A4</option>
                              <option value="A5">A5</option>
                              <option value="A6">A6</option>
                              <option value="A7">A7</option>
                              <option value="A8">A8</option>
                              <option value="A9">A9</option>
                              <option value="B1">B1</option>
                              <option value="B2">B2</option>
                              <option value="B3">B3</option>
                              <option value="B4">B4</option>
                              <option value="B5">B5</option>
                              <option value="B6">B6</option>
                            </select>
                          </div>
                          <div className="col-12">
                            <label className="form-label">Staff Incharge</label>
                            <select
                              className="form-select"
                              name="incharge_id"
                              value={formData.incharge_id}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="" disabled selected>
                                - Select -
                              </option>
                              {staffs.map((staff) => (
                                <option key={staff.id} value={staff.id}>
                                  {staff.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-12 mt-5 mb-5">
                            <button className="btn btn-theme2" type="submit">
                              Create Class
                            </button>
                          </div>
                        </form>
                      </div>
                    </section>
                  </div>
                  <div className="col-12 col-md-8">
                    <section>
                      <div className="row mt-5 mb-5">
                        <div className="iconbox color2 up10">
                          <div className="iconval wow shake">
                            <i className="fas fa-section"></i>
                          </div>
                        </div>
                        <div className="heading">
                          <h4>Class List</h4>
                        </div>
                      </div>
                      <div className="row">
                        <table
                          id=""
                          className="display"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>Id</th>
                              <th>Class</th>
                              <th>Section</th>
                              <th>Staff Incharge</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {classrooms.map((classroom, index) => (
                              <tr key={classroom.id}>
                                <td>{index + 1}</td>
                                <td>{classroom.class}</td>
                                <td>{classroom.section}</td>
                                <td>{classroom.incharge_name}</td>
                                <td className="text-center">
                                  <a
                                    href=" "
                                    className="tablebutton bg-primary text-white"
                                  >
                                    <i className="fas fa-edit"></i>
                                  </a>
                                  <a
                                    href=" "
                                    className="tablebutton bg-danger text-white"
                                  >
                                    <i className="fas fa-trash"></i>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
