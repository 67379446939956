import React, { useState, useEffect } from "react";
import Sidenav from "../../../Pages/nav/Sidenav";
import Footer from "../../footer/Footer";
import Topnav from "../../../Pages/nav/Topnav";
import createtc from "../../../api/Administration/createtc";

function Createtc() {
  const [tcNumber, setTcNumber] = useState("");
  const [erpNumber, setErpNumber] = useState("");
  const [studentDetails, setStudentDetails] = useState({
    name: "",
    admissionNo: "",
    tc_number: "",
    erp_number: "",
    dob: "",
    nationality: "",
    fatherName: "",
    motherName: "",
    motherTongue: "",
    dateOfAdmission: "",
    admitted_grade: "",
    present_grade: "",
    charecter_conduct: "",
    attendance_close: "",
    endOfAY: "",
    issue_date: "",
    prepared_by: "",
    id: "", // Include id in studentDetails
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const generateTcNumber = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const uniqueId = "001"; // This can be incremented or generated based on your logic

      return `SDATC${year}-${month}-${day}${uniqueId}`;
    };

    setTcNumber(generateTcNumber());
  }, []);

  const handleSearch = async () => {
    try {
      const response = await createtc.gettcdetails(erpNumber);
      if (response.length > 0) {
        const student = response[0];
        setStudentDetails({
          ...studentDetails,
          id: student.id, // Store id in studentDetails
          name: student.full_name,
          tc_number: tcNumber,
          erp_number: erpNumber,
          admissionNo: student.admissionNo,
          dob: student.dob,
          nationality: student.nationality,
          fatherName: student.fathers_name,
          motherName: student.mothers_name,
          motherTongue: student.motherTongue, // Adjust according to API response
          dateOfAdmission: student.dateOfAdmission, // Adjust according to API response
          endOfAY: student.endOfAY, // Adjust according to API response
        });
      } else {
        console.error("No student found with ERP number:", erpNumber);
      }
    } catch (error) {
      console.error("Error fetching student info:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tcData = {
      tcNumber,
      erpNumber,
      student_id: studentDetails.id, // Include student_id in tcData
      ...studentDetails,
    };

    try {
      const response = await createtc.createtc(tcData);
      setSuccessMessage("Your TC has been saved successfully");
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        setSuccessMessage("");
      }, 3000); // Close modal after 3 seconds
    } catch (error) {
      console.error("Error creating TC:", error);
    }
  };

  return (
    <div className="nav-fixed">
      <Topnav />
      <div id="layoutSidenav">
        <div>
          <Sidenav />
        </div>
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid p-3">
                <section className="usersprofile mt-5 wow fadeInLeft">
                  <div className="row mt-5">
                    <div className="iconbox color2 up10">
                      <div className="iconval wow shake">
                        <i className="fa-solid fa-file-circle-plus"></i>
                      </div>
                    </div>
                    <div className="heading">
                      <h4>Create TC</h4>
                    </div>
                  </div>
                  {successMessage && isModalOpen && (
                    <div className="modal" style={{
                      display: "block",
                      position: "fixed",
                      zIndex: 1050,
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      outline: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}>
                      <div className="modal-dialog" style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}>
                        <div className="modal-content" style={{
                          backgroundColor: "#fff",
                          border: "none",
                          borderRadius: "0.3rem",
                          boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.5)",
                          maxWidth: "500px",
                          margin: "auto",
                          padding: "1rem",
                          textAlign: "center",
                        }}>
                          <div className="modal-body">
                            <p>{successMessage}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row mt-5">
                    <div className="col-12">
                      <form className="row g-4" onSubmit={handleSubmit}>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            ERP Number <span>*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              name="erpnumber"
                              value={erpNumber}
                              onChange={(e) => setErpNumber(e.target.value)}
                              required
                            />
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={handleSearch}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            TC Number <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="tcno"
                            value={tcNumber}
                            readOnly
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            Admission Number <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="admissionno"
                            value={studentDetails.admissionNo}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                admissionNo: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12">
                          <label className="form-label">
                            Student Name <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={studentDetails.name}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            DOB <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="dob"
                            value={studentDetails.dob}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                dob: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            Nationality<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="nationality"
                            value={studentDetails.nationality}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                nationality: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            Father's Name<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="father"
                            value={studentDetails.fatherName}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                fatherName: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            Mother's Name<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="mother"
                            value={studentDetails.motherName}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                motherName: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            Mother Tongue<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="motherTongue"
                            value={studentDetails.motherTongue}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                motherTongue: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            Date of Admission<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="dateOfAdmission"
                            value={studentDetails.dateOfAdmission}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                dateOfAdmission: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            Admitted Grade<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="admitted_grade"
                            value={studentDetails.admitted_grade}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                admitted_grade: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            Present Grade<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="present_grade"
                            value={studentDetails.present_grade}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                present_grade: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            Character & Conduct<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="charecter_conduct"
                            value={studentDetails.charecter_conduct}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                charecter_conduct: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            Last Date of Attendance<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="attendance_close"
                            value={studentDetails.attendance_close}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                attendance_close: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            End of Academic Year<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="endOfAY"
                            value={studentDetails.endOfAY}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                endOfAY: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            Date of Issue of TC<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="issue_date"
                            value={studentDetails.issue_date}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                issue_date: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label">
                            Prepared By<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="prepared_by"
                            value={studentDetails.prepared_by}
                            onChange={(e) =>
                              setStudentDetails({
                                ...studentDetails,
                                prepared_by: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <button className="btn btn-theme2" type="submit">
                            Create TC
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Createtc;
