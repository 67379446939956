import React from "react";
import Sidenav from "../nav/Sidenav";
import Footer from "../../views/footer/Footer";

function Viewprofile() {
    return (
        <div id="layoutSidenav"><Sidenav />
            <div id="layoutSidenav_content">
                <main>
                    <div className="container-fluid px-4">
                        <section className="usersprofile mt-5 wow fadeInLeft">
                            <div className="row mt-5">
                                <div className="iconbox color2 up10">
                                    <div className="iconval wow shake"><i className="fa-solid fa-user"></i></div>
                                </div>
                                <div className="heading">
                                    <h4>Profile</h4>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12 col-md-6 col-lg-3">
                                    <img src="../assets/img/profile.jpg" className="userimg img-fluid" alt="profile" />
                                    <h6> ERP Number:</h6>
                                </div>
                                <div className="col-12 col-md-6 col-lg-5">
                                    <h6>Student Name:</h6>
                                    <h6>Roll Number:</h6>
                                    <h6>Class:</h6>
                                    <h6>Section:</h6>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <h6>Admission Date:</h6>
                                    <h6>Age:</h6>
                                    <h6>DOB:</h6>
                                    <h6>Gender:</h6>

                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12 col-md-4">
                                    <h6><u>Father Details</u></h6>
                                    <h6>Fathers Name:</h6>
                                    <h6>Contact Number:</h6>
                                    <h6>Contact Email:</h6>
                                </div>
                                <div className="col-12 col-md-4">
                                    <h6><u>Mother Details</u></h6>
                                    <h6>Mothers Name:</h6>
                                    <h6>Contact Number:</h6>
                                    <h6>Contact Email:</h6>
                                    
                                </div>
                                <div className="col-12 col-md-4">
                                    <h6><u>Other Contact Details</u></h6>
                                    <h6>Primary Contact:</h6>
                                    <h6>Alternate Contact:</h6>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12 col-md-4">
                                    <h6>Permanent Address Details:</h6>
                                    <h6><span></span></h6>
                                </div>
                                <div className="col-12 col-md-4">
                                    <h6>Present Address Details:</h6>
                                    <h6><span></span></h6>

                                </div>
                                <div className="col-12 col-md-4">
                                    <h6>Other Information:</h6>
                                    <h6>Id Mark1 : </h6>
                                    <h6>Id Mark2 : </h6>
                                </div>

                            </div>

                            <div className="row mt-5">
                                <u className="mb-3 pb-3">Fee Details</u>
                                <div className="col-12 col-md-4">
                                    <div className=" feebox feecolor2">
                                        <b> Total Fee :</b>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="feebox feecolor1">
                                        <b> Paid Fee :</b>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className=" feebox feecolor3">
                                        <b> Remaining Fee :</b>
                                    </div>
                                </div>
                            </div>


                            <div className="row mt-5">
                                <u className="mb-3 pb-3">Attendance</u>
                                <div className="col-12 col-md-2">
                                    <div className="attenbox text-center">
                                        <b>May</b>
                                        <p className="text-white">0/0</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="attenbox text-center">
                                        <b>June</b>
                                        <p className="text-white">0/0</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="attenbox text-center">
                                        <b>July</b>
                                        <p className="text-white">0/0</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="attenbox text-center">
                                        <b>Aug</b>
                                        <p className="text-white">0/0</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="attenbox text-center">
                                        <b>Sep</b>
                                        <p className="text-white">0/0</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="attenbox text-center">
                                        <b>Oct</b>
                                        <p className="text-white">0/0</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="attenbox text-center">
                                        <b>Nov</b>
                                        <p className="text-white">0/0</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="attenbox text-center">
                                        <b>Dec</b>
                                        <p className="text-white">0/0</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="attenbox text-center">
                                        <b>Jan</b>
                                        <p className="text-white">0/0</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="attenbox text-center">
                                        <b>Feb</b>
                                        <p className="text-white">0/0</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="attenbox text-center">
                                        <b>March</b>
                                        <p className="text-white">0/0</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="attenbox text-center">
                                        <b>April</b>
                                        <p className="text-white">0/0</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer />
            </div>
        </div>

    )
}

export default Viewprofile;