import axios from "axios";
import API from "../../config";
import { retrieveAuthToken } from "../authentication";

const token = retrieveAuthToken();

const createtc = {
  gettcdetails: async (erpNumber) => {
    try {
      const response = await axios.get(
        `${API}/students/info/regnum/${erpNumber}`,
        {
          headers: {
            Authorization: token,
            schoolId: process.env.REACT_APP_SCHOOL_ID,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching student info:", error);
      throw error;
    }
  },
  createtc: async (tcData) => {
    try {
      const response = await axios.post(`${API}/tc`, tcData, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating TC:", error);
      throw error;
    }
  },
};

export default createtc;
