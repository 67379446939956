import React, { useState, useEffect } from "react";
import axios from "axios";
import API from "../../../config/index";
import { retrieveAuthToken } from "../../../api/authentication";
import Topnav from "../../../Pages/nav/Topnav";
import Sidenav from "../../../Pages/nav/Sidenav";

function Absentees() {
    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const [date, setDate] = useState("");
    const [classes, setClasses] = useState([]);
    const [absentees, setAbsentees] = useState({});
    const [error, setError] = useState("");
    const token = retrieveAuthToken();

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const response = await axios.get(`${API}/classroom/`, {
                    headers: {
                        Authorization: token,
                        schoolId: process.env.REACT_APP_SCHOOL_ID,
                    },
                });
                setClasses(response.data);
            } catch (error) {
                setError("Error fetching classes");
                console.error("Error fetching classes:", error);
            }
        };

        fetchClasses();
    }, [token]);

    const handleDateChange = async (event) => {
        const selectedDate = event.target.value;
        setDate(selectedDate);
        try {
            const response = await axios.get(`${API}/students/report/absentees`, {
                params: { date: selectedDate },
                headers: {
                    Authorization: token,
                    schoolId: process.env.REACT_APP_SCHOOL_ID,
                },
            });
            setAbsentees(response.data);
        } catch (error) {
            setError("Error fetching absentees");
            console.error("Error fetching absentees:", error);
        }
    };


    return (
        <>
            <div className="nav-fixed">
                <Topnav toggleSidebar={toggleSidebar} />
                <div id="layoutSidenav">
                    {isSidebarVisible && (
                        <div>
                            <Sidenav />
                        </div>
                    )}
                    <div id="layoutSidenav_content">
                        <div>
                            <main>
                                <div className="container-fluid p-3">
                                    <h1 className="mt-4 mb-2">Absentees Report</h1>
                                    <section>
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        <div className="mb-4">
                                            <label className="form-label">Select Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={date}
                                                onChange={handleDateChange}
                                            />
                                        </div>
                                        {date && (
                                            <div>
                                                {Object.keys(absentees).map((classId) => (
                                                    <div key={classId} className="mb-5">
                                                        <h4>
                                                            {classes.find((classItem) => classItem.id === parseInt(classId))?.class}
                                                            {classes.find((classItem) => classItem.id === parseInt(classId))?.section}
                                                        </h4>
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Id</th>
                                                                    <th>ERP ID</th>
                                                                    <th>Name</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {absentees[classId]?.length > 0 ? (
                                                                    absentees[classId].map((student) => (
                                                                        <tr key={student.id}>
                                                                            <td>{student.id}</td>
                                                                            <td>{student.erp_num}</td>
                                                                            <td>{student.name}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="3" className="text-center">
                                                                            No absentees
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </section>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Absentees;
