import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import Sidenav from "../../../Pages/nav/Sidenav";
import Footer from "../../footer/Footer";
import Topnav from "../../../Pages/nav/Topnav";

function Staffrequest() {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  return (
    <div className="nav-fixed">
      <Topnav toggleSidebar={toggleSidebar} />
      <div id="layoutSidenav">
        {isSidebarVisible && (
          <div>
            <Sidenav />
          </div>
        )}
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid ">
                <section>
                  <div className="row mb-5">
                    <div className="iconbox color2 up10">
                      <div className="iconval wow shake">
                        <i className="fa-solid fa-check-to-slot"></i>
                      </div>
                    </div>
                    <div className="heading">
                      <h4>Staff Request</h4>
                    </div>
                  </div>
                  <div className="row">
                    <table
                      id=""
                      className="display responsive nowrap"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Name</th>
                          <th>Substitute</th>
                          <th>Date</th>
                          <th>Request Type</th>
                          <th>Reason</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="text-center">
                            <a
                              href=" "
                              className="tablebutton bg-success text-white"
                            >
                              <i className="fas fa-check"></i>
                            </a>
                            <a
                              href=" "
                              className="tablebutton color7 text-white"
                            >
                              <i className="fas fa-close"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Staffrequest;
