import React from "react";
// import Dashboard from "../Dashboard";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../css/dash.css";


function Sidenav() {

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/", { replace: true });
  };
  return (
    <div>


      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <nav className="sidenav accordion sidenav-dark" id="sidenavAccordion">
            <div className="sidenav-menu">
              <div className="nav">
                <div className="sidenav-menu-heading">Core</div>
                <Link className="nav-link" to="/dashboard">
                  <div className="nav-link-icon">
                    <i className="fas fa-tachometer-alt"></i>
                  </div>
                  Dashboard
                </Link>
                <a className="nav-link" href="admin">
                  <div className="nav-link-icon">
                    <i className="fas fa-user-shield"></i>
                  </div>
                  Admin Details
                </a>
                <div className="sidenav-menu-heading">Users</div>
                <a
                  className="nav-link collapsed"
                  href="  "
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseLayouts1"
                  aria-expanded="false"
                  aria-controls="collapseLayouts1"
                >
                  <div className="nav-link-icon">
                    <i className="fa-solid fa-user-tie"></i>
                  </div>
                  Staff
                  <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </a>
                <div
                  className="collapse"
                  id="collapseLayouts1"
                  aria-labelledby="headingOne"
                  data-bs-parent="#sidenavAccordion"
                >
                  <nav className="sidenav-menu-nested nav">
                    <a className="nav-link" href="staffdetails">
                      <div className="nav-link-icon">
                        <i className="fas fa-user-tie"></i>
                      </div>
                      Add Staff
                    </a>
                    <a className="nav-link" href="staffprofile">
                      <div className="nav-link-icon">
                        <i className="fa-regular fa-id-badge"></i>
                      </div>
                      View Staff
                    </a>
                    {/* <a className="nav-link" href="staffattendance">
                      <div className="nav-link-icon">
                        <i className="fas fa-clipboard-check"></i>
                      </div>{" "}
                      Staff Attendance
                    </a>
                    <a className="nav-link" href="staffrequest">
                      <div className="nav-link-icon">
                        <i className="fas fa-check-to-slot"></i>
                      </div>
                      Staff Request
                    </a> */}
                  </nav>
                </div>
                <a
                  className="nav-link collapsed"
                  href="  "
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseLayouts2"
                  aria-expanded="false"
                  aria-controls="collapseLayouts2"
                >
                  <div className="nav-link-icon">
                    <i className="fa-solid fa-user-graduate"></i>
                  </div>
                  Student
                  <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </a>
                <div
                  className="collapse"
                  id="collapseLayouts2"
                  aria-labelledby="headingOne"
                  data-bs-parent="#sidenavAccordion"
                >
                  <nav className="sidenav-menu-nested nav">
                    <a className="nav-link" href="studentdetails">
                      <div className="nav-link-icon">
                        <i className="fas fa-user-plus"></i>
                      </div>
                      Add Students
                    </a>
                    <a className="nav-link" href="liststudents">
                      <div className="nav-link-icon">
                        <i className="fas fa-clipboard-check"></i>
                      </div>{" "}
                      List Students
                    </a>
                    <a className="nav-link" href="studentprofile">
                      <div className="nav-link-icon">
                        <i className="fas fa-user-graduate"></i>
                      </div>
                      View Profile
                    </a>
                    <a className="nav-link" href="studentattendance">
                      <div className="nav-link-icon">
                        <i className="fas fa-clipboard-check"></i>
                      </div>{" "}
                      Mark Attendance
                    </a>
                    <a className="nav-link" href="viewattendance">
                      <div className="nav-link-icon">
                        <i className="fas fa-clipboard-check"></i>
                      </div>{" "}
                      View Attendance
                    </a>
                  </nav>
                </div>
                <div className="sidenav-menu-heading">Class</div>
                <a
                  className="nav-link collapsed"
                  href="  "
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseLayouts3"
                  aria-expanded="false"
                  aria-controls="collapseLayouts3"
                >
                  <div className="nav-link-icon">
                    <i className="fa-solid fa-chalkboard-user"></i>
                  </div>
                  Class
                  <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </a>
                <div
                  className="collapse"
                  id="collapseLayouts3"
                  aria-labelledby="headingOne"
                  data-bs-parent="#sidenavAccordion"
                >
                  <nav className="sidenav-menu-nested nav">
                    <a className="nav-link" href="section">
                      <div className="nav-link-icon">
                        <i className="fas fa-section"></i>
                      </div>
                      Create Class
                    </a>
                    <a className="nav-link" href="subject">
                      <div className="nav-link-icon">
                        <i className="fas fa-book"></i>
                      </div>
                      Create Subject
                    </a>
                    <a className="nav-link" href="classallocation">
                      <div className="nav-link-icon">
                        <i className="fas fa-person-chalkboard"></i>
                      </div>{" "}
                      Allocate Class                    </a>
                    <a className="nav-link" href="timetable">
                      <div className="nav-link-icon">
                        <i className="fa fa-calendar-days"></i>
                      </div>
                      Manage Time Table
                    </a>
                    {/* <a className="nav-link" href="setupexam">
                      <div className="nav-link-icon">
                        <i className="fa-solid fa-file-pen"></i>
                      </div>
                      Setup Exam
                    </a> */}
                  </nav>
                </div>


                {/* -------------------------------------------------------------------- */}

                <div className="sidenav-menu-heading">Announcements</div>
                <a
                  className="nav-link collapsed"
                  href="  "
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseLayouts4"
                  aria-expanded="false"
                  aria-controls="collapseLayouts4"
                >
                  <div className="nav-link-icon">
                    <i className="fa-solid fa-chalkboard-user"></i>
                  </div>
                  Announcements
                  <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </a>
                <div
                  className="collapse"
                  id="collapseLayouts4"
                  aria-labelledby="headingOne"
                  data-bs-parent="#sidenavAccordion"
                >
                  <nav className="sidenav-menu-nested nav">
                    <a className="nav-link" href="homeworkannouncement">
                      <div className="nav-link-icon">
                        <i className="fas fa-section"></i>
                      </div>
                      Homework
                    </a>
                    <a className="nav-link" href="examannouncement">
                      <div className="nav-link-icon">
                        <i className="fas fa-book"></i>
                      </div>
                      Exam
                    </a>
                    {/* <a className="nav-link" href="classallocation">
                      <div className="nav-link-icon">
                        <i className="fas fa-person-chalkboard"></i>
                      </div>{" "}
                      Class Allocation
                    </a>
                    <a className="nav-link" href="timetable">
                      <div className="nav-link-icon">
                        <i className="fa fa-calendar-days"></i>
                      </div>
                      Timetable
                    </a> */}
                  </nav>
                </div>
                {/* <div className="sidenav-menu-heading">Payments</div>
                <a
                  className="nav-link collapsed"
                  href="  "
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseLayouts4"
                  aria-expanded="false"
                  aria-controls="collapseLayouts4"
                >
                  <div className="nav-link-icon">
                    <i className="fa-solid fa-dollar-sign"></i>
                  </div>
                  Fees
                  <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </a>
                <div
                  className="collapse"
                  id="collapseLayouts4"
                  aria-labelledby="headingOne"
                  data-bs-parent="#sidenavAccordion"
                >
                  <nav className="sidenav-menu-nested nav">
                    <a className="nav-link" href="feeplan">
                      <div className="nav-link-icon">
                        <i className="fas fa-money-check"></i>
                      </div>
                      Fee Plan
                    </a>
                    <a className="nav-link" href="feestructure">
                      <div className="nav-link-icon">
                        <i className="fas fa-square-poll-horizontal"></i>
                      </div>
                      Fee Structure
                    </a>
                    <a className="nav-link" href="feeconcession">
                      <div className="nav-link-icon">
                        <i className="fas fa-money-bill-transfer"></i>
                      </div>
                      Fee Concession
                    </a>
                    <a className="nav-link" href="feemode">
                      <div className="nav-link-icon">
                        <i className="fas fa-file-invoice-dollar"></i>
                      </div>
                      Fee Mode
                    </a>
                  </nav>
                </div>
                <a
                  className="nav-link collapsed"
                  href="  "
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseLayouts5"
                  aria-expanded="false"
                  aria-controls="collapseLayouts5"
                >
                  <div className="nav-link-icon">
                    <i className="fas fa-money-bill-1-wave"></i>
                  </div>
                  Payroll
                  <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </a>
                <div
                  className="collapse"
                  id="collapseLayouts5"
                  aria-labelledby="headingOne"
                  data-bs-parent="#sidenavAccordion"
                >
                  <nav className="sidenav-menu-nested nav">
                    <a className="nav-link" href="payrollbase">
                      <div className="nav-link-icon">
                        <i className="fas fa-file-invoice-dollar"></i>
                      </div>
                      Payroll Base
                    </a>
                    <a className="nav-link" href="payrolldetails  ">
                      <div className="nav-link-icon">
                        <i className="fas fa-file-invoice-dollar"></i>
                      </div>
                      Payroll
                    </a>
                  </nav>
                </div> */}
                <div className="sidenav-menu-heading">Administration</div>
                <a
                  className="nav-link collapsed"
                  href="  "
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseLayouts6"
                  aria-expanded="false"
                  aria-controls="collapseLayouts6"
                >
                  <div className="nav-link-icon">
                    <i className="fas fa-certificate"></i>
                  </div>
                  Tc
                  <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </a>
                <div
                  className="collapse"
                  id="collapseLayouts6"
                  aria-labelledby="headingOne"
                  data-bs-parent="#sidenavAccordion"
                >
                  <nav className="sidenav-menu-nested nav">
                    <a className="nav-link" href="createtc">
                      <div className="nav-link-icon">
                        <i className="fa-solid fa-file-circle-plus"></i>
                      </div>
                      Create TC
                    </a>
                    {/* <a className="nav-link" href="managetc">
                      <div className="nav-link-icon">
                        <i className="fa-solid fa-file-pen"></i>
                      </div>
                      Manage TC
                    </a> */}
                  </nav>
                </div>
                <a
                  className="nav-link collapsed"
                  href="  "
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseLayouts7"
                  aria-expanded="false"
                  aria-controls="collapseLayouts7"
                >
                  <div className="nav-link-icon">
                    <i className="fas fa-users-rectangle"></i>
                  </div>
                  Visitors
                  <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </a>
                <div
                  className="collapse"
                  id="collapseLayouts7"
                  aria-labelledby="headingOne"
                  data-bs-parent="#sidenavAccordion"
                >
                  <nav className="sidenav-menu-nested nav">
                    <a className="nav-link" href="visitors">
                      <div className="nav-link-icon">
                        <i className="fas fa-user-group"></i>
                      </div>
                      Visitor
                    </a>
                    <a className="nav-link" href="parcel">
                      <div className="nav-link-icon">
                        <i className="fas fa-box"></i>
                      </div>
                      Parcel
                    </a>
                    {/* <a className="nav-link" href="gatepass">
                      <div className="nav-link-icon">
                        <i className="fas fa-ticket"></i>
                      </div>
                      Gatepass
                    </a> */}
                  </nav>
                </div>
                {/* <a
                  className="nav-link collapsed"
                  href="  "
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseLayouts8"
                  aria-expanded="false"
                  aria-controls="collapseLayouts8"
                >
                  <div className="nav-link-icon">
                    <i className="fa-solid fa-comments"></i>
                  </div>
                  Communication
                  <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </a>
                <div
                  className="collapse"
                  id="collapseLayouts8"
                  aria-labelledby="headingOne"
                  data-bs-parent="#sidenavAccordion"
                >
                  <nav className="sidenav-menu-nested nav">
                    <a className="nav-link" href="announcement">
                      <div className="nav-link-icon">
                        <i className="fas fa-bullhorn"></i>
                      </div>
                      Announcement
                    </a>
                    {/* <a className="nav-link" href="sendmessage">
                      <div className="nav-link-icon">
                        <i className="fas fa-envelope"></i>
                      </div>
                      Send Message
                    </a> 
              </nav>
            </div>
            <div className="sidenav-menu-heading">Others</div> */}
                {/* <a className="nav-link" href="medicalcondition">
                  <div className="nav-link-icon">
                    <i className="fas fa-pills"></i>
                  </div>
                  Medical Condition
                </a> */}
                <a className="nav-link mt-5 mb-5" href=" " onClick={handleLogout}>
                  <div className="nav-link-icon">
                    <i className="fas fa-right-from-bracket"></i>
                  </div>{" "}
                  Logout
                </a>
              </div>
            </div>
            {/*<div className="sidenav-footer">
            <a className="nav-link" href="  ">
              <div className="nav-link-icon"><i className="fas fa-right-from-bracket"></i> Logout</div>
            </a>
          </div>*/}
          </nav>
        </div >
      </div >
    </div >
  );
}

export default Sidenav;
