import axios from "axios";
import API from "../config/index";
import { retrieveAuthToken } from "./authentication";

const token = retrieveAuthToken();

const Classroom = {
  getClassroom: async (classId) => {
    try {
      const response = await axios.get(`${API}/classroom/`, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching classroom data:", error);
      throw error;
    }
  },

  createClassroom: async (classroomData) => {
    try {
      const response = await axios.post(`${API}/classroom/`, classroomData, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating classroom:", error);
      throw error;
    }
  },

  // deleteClassroom: async (classId) => {
  //   try {
  //     const response = await axios.post(`${API}/classroom/`, classroomData, {
  //       headers: {
  //         Authorization: token,
  //       },
  //     });
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error creating classroom:", error);
  //     throw error;
  //   }
  // },
};

export default Classroom;
