import axios from "axios";
import API from "../config/index";
import { retrieveAuthToken } from "./authentication";

const token = retrieveAuthToken();

const Timetable = {
  //   getSubjects: async (classId) => {
  //     try {
  //       const response = await axios.get(`${API}/courses/`, {
  //         headers: {
  //           Authorization: token,
  //         },
  //       });
  //       return response.data;
  //     } catch (error) {
  //       console.error("Error fetching classroom data:", error);
  //       throw error;
  //     }
  //   },

  //   getSubjectsbyClass: async (classId) => {
  //     try {
  //       const response = await axios.get(`${API}/courses/class/${classId}`, {
  //         headers: {
  //           Authorization: token,
  //         },
  //       });
  //       return response.data;
  //     } catch (error) {
  //       console.error("Error fetching classroom data:", error);
  //       throw error;
  //     }
  //   },

  createTimeTable: async (timetableData) => {
    try {
      const response = await axios.post(`${API}/timetable/`, timetableData, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating classroom:", error);
      throw error;
    }
  },

  fetchTimetable: async (classId) => {
    try {
      const response = await axios.get(`${API}/timetable/class/${classId}`, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching timetable:", error);
      throw error;
    }
  },

  //   allocateStaff: async (timetableData) => {
  //     try {
  //       const response = await axios.post(`${API}/courses/`, timetableData, {
  //         headers: {
  //           Authorization: token,
  //         },
  //       });
  //       return response.data;
  //     } catch (error) {
  //       console.error("Error creating classroom:", error);
  //       throw error;
  //     }
  //   },
};
export default Timetable;
