import axios from "axios";
import API from "../../../config";
import { retrieveAuthToken } from "../../authentication";

const token = retrieveAuthToken();

const createPass = {
  addVisitors: async (visitorData) => {
    try {
      const response = await axios.post(
        `${API}/visitors/addvisitor`,
        visitorData,
        {
          headers: {
            Authorization: token,
            schoolId: process.env.REACT_APP_SCHOOL_ID, // Assuming you're storing the token in localStorage
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error adding visitor:", error);
      throw error;
    }
  },

  getVisitros: async (visitorData) => {
    try {
      const response = await axios.get(
        `${API}/visitors/getvisitor`,
        visitorData,
        {
          headers: {
            Authorization: token,
            schoolId: process.env.REACT_APP_SCHOOL_ID,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log("Erron getting visitor:", error);
      throw error;
    }
  },

  getclassroomid: async (classId) => {
    try {
      const response = await axios.get(`${API}/students/class/${classId}`, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.log("Error getting classroomid:", error);
      throw error;
    }
  },
};

export default createPass;
