import React, { useEffect, useState } from "react";
import Sidenav from "../../Pages/nav/Sidenav";
import Footer from "../footer/Footer";
import WOW from "wowjs";
import Topnav from "../../Pages/nav/Topnav";
import ClassroomAPI from "../../api/classroom"; // Import Classroom API functions
import StaffAPI from "../../api/staff"; // Import Staff API functions
import SubjectAPI from "../../api/subject"; // Import Subject API functions
import axios from "axios";
import API from "../../config/index";
import { retrieveAuthToken } from "../../api/authentication"; // Import the token retrieval function

const token = retrieveAuthToken();

function Classallocation() {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [classrooms, setClassrooms] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [allocations, setAllocations] = useState([]); // State for allocations
  const [formData, setFormData] = useState({
    class: "",
    teacher: "",
    subject: "",
  });

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();

    const fetchClassrooms = async () => {
      try {
        const classroomData = await ClassroomAPI.getClassroom();
        setClassrooms(classroomData);
      } catch (error) {
        console.error("Error fetching classroom data:", error);
      }
    };

    const fetchTeachingStaffs = async () => {
      try {
        const teachersData = await StaffAPI.getTeachingStaffs();
        setTeachers(teachersData);
      } catch (error) {
        console.error("Error fetching teaching staffs:", error);
      }
    };

    const fetchAllocations = async () => {
      try {
        const allocationsData = await SubjectAPI.getSubjects(); // Assuming this API fetches the allocations
        setAllocations(allocationsData);
      } catch (error) {
        console.error("Error fetching allocations:", error);
      }
    };

    fetchClassrooms();
    fetchTeachingStaffs();
    fetchAllocations(); // Fetch allocations when component mounts
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleClassChange = async (e) => {
    const classId = e.target.value;
    setFormData({ ...formData, class: classId });

    try {
      const subjectsData = await SubjectAPI.getSubjectsbyClass(classId);
      setSubjects(subjectsData);
    } catch (error) {
      console.error("Error fetching subjects for class:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const courseData = {
        incharge_id: formData.teacher,
        classroom_id: formData.class,
        name: formData.subject,
      };
      const data = await SubjectAPI.allocateStaff(courseData);
      // Optionally refetch allocations after submitting the form
      const allocationsData = await SubjectAPI.getAllocations();
      setAllocations(allocationsData);
    } catch (error) {
      console.error("Error allocating class:", error);
    }
  };

  return (
    <div className="nav-fixed">
      <Topnav toggleSidebar={toggleSidebar} />
      <div id="layoutSidenav">
        {isSidebarVisible && (
          <div>
            <Sidenav />
          </div>
        )}
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid p-3">
                <h1 className="mt-4 mb-2">Class Allocation</h1>

                <div className="row">
                  <form className="row mb-5" onSubmit={handleSubmit}>
                    <div className="col-12 col-md-4">
                      <section className="wow fadeInLeft">
                        <div className="container">
                          <div className="row g-4 mb-5">
                            <h5>
                              <u>Class Allocation</u>
                            </h5>
                            <div className="col-12">
                              <label className="form-label">
                                Class <span>*</span>
                              </label>
                              <select
                                className="form-select"
                                name="class"
                                value={formData.class}
                                onChange={handleClassChange}
                                required
                              >
                                <option value=""> - Select - </option>
                                {classrooms.map((classroom) => (
                                  <option
                                    key={classroom.id}
                                    value={classroom.id}
                                  >
                                    {classroom.class + " " + classroom.section}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-12">
                              <label className="form-label">
                                Teacher <span>*</span>
                              </label>
                              <select
                                className="form-select"
                                name="teacher"
                                value={formData.teacher}
                                onChange={handleInputChange}
                                required
                              >
                                <option value=""> - Select - </option>
                                {teachers.map((teacher) => (
                                  <option key={teacher.id} value={teacher.id}>
                                    {teacher.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-12">
                              <label className="form-label">
                                Subject <span>*</span>
                              </label>
                              <select
                                className="form-select"
                                name="subject"
                                value={formData.subject}
                                onChange={handleInputChange}
                                required
                              >
                                <option value=""> - Select - </option>
                                {subjects.map((subject) => (
                                  <option key={subject.id} value={subject.name}>
                                    {subject.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-12">
                              <button className="btn btn-theme2" type="submit">
                                Allocate Class
                              </button>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="col-12 col-md-8">
                      <section>
                        <div className="row mb-5">
                          <div className="iconbox color2 up10">
                            <div className="iconval wow shake">
                              <i className="fas fa-person-chalkboard"></i>
                            </div>
                          </div>
                          <div className="heading">
                            <h4>Class Staff List</h4>
                          </div>
                        </div>
                        <div className="row">
                          <table
                            id=""
                            className="display"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>Id</th>
                                <th>Class</th>
                                <th>Teacher</th>
                                <th>Subject</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allocations.map((allocation, index) => (
                                <tr key={allocation.id}>
                                  <td>{index + 1}</td>  
                                  <td>{allocation.class + " " + allocation.section}</td>
                                  <td>{allocation.incharge_name}</td>
                                  <td>{allocation.name}</td>
                                  <td>
                                    <a
                                      href=" "
                                      className="tablebutton bg-primary text-white"
                                    >
                                      <i className="fas fa-edit"></i>
                                    </a>
                                    <a
                                      href=" "
                                      className="tablebutton bg-danger text-white"
                                    >
                                      <i className="fas fa-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </section>
                    </div>
                  </form>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Classallocation;
