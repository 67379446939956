import axios from "axios";
import API from "../config/index";
import { retrieveAuthToken } from "./authentication";



const Staff = {
  addStaff: async (staffData) => {
    const token = retrieveAuthToken();
    try {
      const response = await axios.post(`${API}/employee/addStaff`, staffData, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating student:", error);
      throw error;
    }
  },

  getTeachingStaffs: async (classId) => {
    const token = retrieveAuthToken();
    try {
      const response = await axios.get(`${API}/employee/TS`, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching classroom data:", error);
      throw error;
    }
  },

  getNonTeachingStaffs: async (classId) => {
    const token = retrieveAuthToken();
    try {
      const response = await axios.get(`${API}/employee/NTS`, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching classroom data:", error);
      throw error;
    }
  },

  createClassroom: async (classroomData) => {
    const token = retrieveAuthToken();
    try {
      const response = await axios.post(`${API}/employee/`, classroomData, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating classroom:", error);
      throw error;
    }
  },

  getStaffInfo: async (staffInfoData) => {
    const token = retrieveAuthToken();
    try {
      const response = await axios.get(`${API}/employee/info/id/${staffInfoData}`, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching staff info:", error);
      throw error;
    }
  },
};

export default Staff;
