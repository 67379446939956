import React, { useEffect, useState } from "react";
import Sidenav from "../../Pages/nav/Sidenav";
import Footer from "../footer/Footer";
import WOW from "wowjs";
import Topnav from "../../Pages/nav/Topnav";
import Classroom from "../../api/classroom"; // Make sure the import path is correct
import SubjectAPI from "../../api/subject"; // Import Subject API functions

function Subject() {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [classrooms, setClassrooms] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    classroom_id: "", // Corrected to match API data structure
  });

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();

    const fetchClassrooms = async () => {
      try {
        const classroomData = await Classroom.getClassroom();
        setClassrooms(classroomData);
      } catch (error) {
        console.error("Error fetching classroom data:", error);
      }
    };

    const fetchSubjects = async () => {
      try {
        const subjectData = await SubjectAPI.getSubjects();
        setSubjects(subjectData);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };

    fetchClassrooms();
    fetchSubjects();
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await SubjectAPI.createSubjects(formData);
      const updatedSubjects = await SubjectAPI.getSubjects();
      setSubjects(updatedSubjects);
      setFormData({
        name: "",
        classroom_id: "",
      });
    } catch (error) {
      console.error("Error creating subject:", error);
    }
  };

  return (
    <div className="nav-fixed">
      <Topnav toggleSidebar={toggleSidebar} />
      <div id="layoutSidenav">
        {isSidebarVisible && (
          <div>
            <Sidenav />
          </div>
        )}
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid p-3">
                <h1 className="mt-4 mb-2">Subject Details</h1>

                <div className="row">
                  <div className="col-12 text-end">
                    <a
                      className="textbutton color6 text-white"
                      href="sheet/subject.xlsx"
                      target="blank"
                      id="bulk"
                    >
                      Bulk Upload
                    </a>
                    <a
                      className="textbutton color6 text-white hideclass"
                      href="sheet/subject.xlsx"
                      id="upload"
                    >
                      <i className="fa-solid fa-upload"></i> Bulk Upload
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <section>
                      <div className="row">
                        <form className="row g-3" onSubmit={handleSubmit}>
                          <h5>
                            <u>Enter Subject Details</u>
                          </h5>
                          <div className="col-12 mt-5">
                            <label className="form-label">Create Subject</label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <label className="form-label">Class</label>
                            <select
                              className="form-select"
                              name="classroom_id" // Corrected to match formData property
                              value={formData.classroom_id}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="" disabled>
                                - Select -
                              </option>
                              {classrooms.map((classroom) => (
                                <option key={classroom.id} value={classroom.id}>
                                  {classroom.class + " " + classroom.section}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-12">
                            <button className="btn btn-theme2" type="submit">
                              Create Subject
                            </button>
                          </div>
                        </form>
                      </div>
                    </section>
                  </div>
                  <div className="col-12 col-md-8">
                    <section>
                      <div className="row mt-5 mb-5">
                        <div className="iconbox color2 up10">
                          <div className="iconval wow shake">
                            <i className="fa-solid fa-book"></i>
                          </div>
                        </div>
                        <div className="heading">
                          <h4>Subject List</h4>
                        </div>
                      </div>
                      <div className="row">
                        <table
                          id=""
                          className="display"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>Id</th>
                              <th>Subject</th>
                              <th>Classroom</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {subjects.map((subject) => (
                              <tr key={subject.id}>
                                <td>{subject.id}</td>
                                <td>{subject.name}</td>
                                <td>{subject.class + " " + subject.section}</td>

                                <td className="text-center">
                                  <a
                                    href=" "
                                    className="tablebutton bg-primary text-white"
                                  >
                                    <i className="fas fa-edit"></i>
                                  </a>
                                  <a
                                    href=" "
                                    className="tablebutton bg-danger text-white"
                                  >
                                    <i className="fas fa-trash"></i>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subject;
