import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import API from "../../config";

function Input() {
  const [userpass, setUserPass] = useState({
    username: "",
    password: "",
    role: "admin", // Default role
  });
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      navigate("/dashboard", { replace: true });
    }
  }, [navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserPass((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      const schoolId = process.env.REACT_APP_SCHOOL_ID;
      const response = await axios.post(
        `${API}/admin/login`,
        {
          email: userpass.username,
          password: userpass.password,
          role: userpass.role,
        },
        {
          headers: {
            schoolId: process.env.REACT_APP_SCHOOL_ID,
          },
        }
      );
      const { token } = response.data;
      localStorage.setItem("token", token);
      navigate("/dashboard", { replace: true });
    } catch (error) {
      console.error("Login failed:", error);
      alert("Your username and password are incorrect");
    }
  };

  return (
    <div>
      <div
        className="tab-pane fade show active"
        id="user-tab-pane"
        role="tabpanel"
        aria-labelledby="user-tab"
        tabIndex="0"
      >
        <form className="g-3 mt-5" id="loginform" onSubmit={handleSignIn}>
          <div className="form-outline mt-2 mb-4">
            <input
              type="radio"
              id="admin"
              name="role"
              value="admin"
              checked={userpass.role === "admin"}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="admin">
              &nbsp;Admin&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </label>
            {/* <input type="radio" id="staff" name="role" value="staff" checked={userpass.role === "staff"} onChange={handleInputChange} />
            <label htmlFor="staff">&nbsp;Staff</label> */}
          </div>
          <div className="form-outline mt-2 mb-4">
            <label className="form-label">Email</label>
            <div className="d-flex justify-content-center">
              <input
                type="text"
                name="username"
                className="form-control"
                value={userpass.username}
                onChange={handleInputChange}
                style={{ width: "95%" }}
              />
            </div>
          </div>
          <div className="form-outline mt-2 mb-4">
            <label className="form-label">Password</label>
            <div className="d-flex justify-content-center">
              <input
                type="password"
                name="password"
                className="form-control"
                value={userpass.password}
                onChange={handleInputChange}
                style={{ width: "95%" }}
              />
            </div>
          </div>
          <div id="resultshow"></div>
          <button type="submit" className="btn btn-primary block mb-4">
            Sign in
          </button>
        </form>
      </div>
    </div>
  );
}

export default Input;
