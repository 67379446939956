// export const saveAuthToken = (token) => {
//     if (window) {
//       localStorage.setItem("jwt", token);
//     }
//   };
  
  // Retrieve auth token
  export const retrieveAuthToken = () => {
    if (window) {
      return localStorage.getItem("token");
    }
    return null;
  };