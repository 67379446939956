import axios from "axios";
import API from "../config/index";
import { retrieveAuthToken } from "./authentication";

const token = retrieveAuthToken();

const Subject = {
  getSubjects: async (classId) => {
    try {
      const response = await axios.get(`${API}/courses/`, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching classroom data:", error);
      throw error;
    }
  },

  getSubjectsbyClass: async (classId) => {
    try {
      const response = await axios.get(`${API}/courses/class/${classId}`, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching classroom data:", error);
      throw error;
    }
  },

  createSubjects: async (courseData) => {
    try {
      const response = await axios.post(`${API}/courses/`, courseData, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating classroom:", error);
      throw error;
    }
  },
  allocateStaff: async (courseData) => {
    try {
      const response = await axios.post(`${API}/courses/`, courseData, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating classroom:", error);
      throw error;
    }
  },
};

// bodySample
// {
//     "classroom_id": 1,
//     "name": "Mathematics",
//     "description": "Advanced mathematics course for high school students"
// }
export default Subject;
