import React, { useEffect, useState } from "react";
import Footer from "../../footer/Footer";
import WOW from "wowjs";
import Sidenav from "../../../Pages/nav/Sidenav";
import Topnav from "../../../Pages/nav/Topnav";
import API from "../../../config/index";
import ImageComponent from "./studentProfilecard";
import { retrieveAuthToken } from "../../../api/authentication";


function Staffprofile() {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [employeeNum, setEmployeeNum] = useState("");
  const [studentData, setStudentData] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false); // State for image loading

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleInputChange = (event) => {
    setEmployeeNum(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const token = retrieveAuthToken();


    setLoading(true); // Set loading state before fetching data
    try {
      const response = await fetch(`${API}/employee/info/id/${employeeNum}`, {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      if (!response.ok) {
        throw new Error(`${response.statusText}`);
      }
      const data = await response.json();
      if (data.length > 0) {
        setStudentData(data[0]); // Assuming studentData is an array and you need the first element
        setError("");
      } else {
        setError("Staff data not found.");
        setStudentData(null);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setError(`Error fetching data: ${error.message}`);
    } finally {
      setLoading(false); // Always clear loading state after fetching data
    }
  };

  return (
    <div className="nav-fixed">
      <Topnav toggleSidebar={toggleSidebar} />
      <div id="layoutSidenav">
        {isSidebarVisible && (
          <div>
            <Sidenav />
          </div>
        )}
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid p-3">
                <div className="row">
                  <form
                    className="row g-2 form-inline"
                    onSubmit={handleFormSubmit}
                  >
                    <div className="col-auto">
                      <input
                        type="text"
                        className="form-control"
                        name="erp"
                        placeholder="Enter ERP Number"
                        value={employeeNum}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn btn-theme2"
                        type="submit"
                        id="getuserdetail"
                      >
                        Get Details
                      </button>
                    </div>
                    <div className="col-auto mt-3 editbutton">
                      <a className="textbutton color2 text-white" href=" ">
                        Edit Profile
                      </a>
                    </div>
                  </form>
                </div>
                {error && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {error}
                  </div>
                )}

                {isLoading ? (
                  <div className="text-center mt-5">
                    <i className="fa fa-spinner fa-spin fa-3x"></i>
                  </div>
                ) : studentData ? (
                  <section
                    className="usersprofile userprofile mt-5 wow fadeInLeft"
                    id="usersprofile"
                  >
                    <div className="row mt-5 align-items-center">
                      <div className="col-12 col-lg-2 mb-3">
                        <div className="iconbox color2 up10">
                          <div className="iconval wow shake">
                            <i className="fa-solid fa-user-graduate"></i>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-10 mb-3">
                        <h2 className="section-title mb-4">Staff Profile</h2>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12 col-md-4 mb-4">
                        <div className="card">
                          {/* <img
                            src={studentData.image_url}
                            className="card-img-top img-fluid"
                            alt="profile"
                            onLoad={() => setLoading(false)} // Clear loading state when image loads
                            onError={() => setLoading(false)} // Clear loading state if image fails to load
                          /> */}

                          <ImageComponent imageUrl={studentData.image_url} />
                          <div className="card-body">
                            <h5 className="card-title">
                              ERP Number: {studentData.erp_num}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-8">
                        <div className="row mb-4">
                          <div className="col-6">
                            <h5>Staff Name:</h5>
                            <p className="mb-3">{studentData.name}</p>
                            <h5>Blood Group</h5>
                            <p className="mb-3">{studentData.blood_group}</p>
                            <h5>Gender</h5>
                            <p className="mb-3">{studentData.gender}</p>
                          </div>
                          <div className="col-6">
                            <h5>Aadhar</h5>
                            <p className="mb-3">{studentData.aadhar}</p>
                            <h5>DOB:</h5>
                            <p className="mb-3">{studentData.date_of_birth}</p>
                            <h5>Marital Status</h5>
                            <p className="mb-3">{studentData.marital_status}</p>
                          </div>
                        </div>
                        <hr></hr>

                        <div className="row mb-4">
                          <div className="col-6">
                            <h5>Father's Name:</h5>
                            <p className="mb-3">{studentData.fathers_name}</p>
                          </div>
                          <div className="col-6">
                            <h5>Mother's Name:</h5>
                            <p className="mb-3">{studentData.mothers_name}</p>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-6">
                            <h5>Primary Contact:</h5>
                            <p className="mb-3">
                              {studentData.phone || "Not Available"}
                            </p>
                            <h5>Alternate Contact:</h5>
                            <p className="mb-3">
                              {studentData.alternate_phone || "Not Available"}
                            </p>
                          </div>
                          <div className="col-6">
                            <h5>Permanent Address:</h5>
                            <p className="mb-3">
                              {studentData.permanent_address +
                                "," +
                                "\n" +
                                studentData.permanent_city +
                                "," +
                                "\n" +
                                studentData.permanent_state +
                                "," +
                                "\n" +
                                studentData.permanent_zip}
                            </p>
                            <h5>Present Address:</h5>
                            <p className="mb-3">
                              {studentData.present_address +
                                "," +
                                "\n" +
                                studentData.present_city +
                                "," +
                                "\n" +
                                studentData.present_state +
                                "," +
                                "\n" +
                                studentData.present_zip}
                            </p>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-6">
                            <h5>ID Mark1:</h5>
                            <p className="mb-3">
                              {studentData.identification_mark_1}
                            </p>
                          </div>
                          <div className="col-6">
                            <h5>ID Mark2:</h5>
                            <p className="mb-3">
                              {studentData.identification_mark_2}
                            </p>
                          </div>
                        </div>

                        <hr></hr>

                        <div className="row mb-4">
                          <div className="col-6">
                            <h5>Experience</h5>
                            <p className="mb-3">
                              {studentData.year_of_experience}
                            </p>
                          </div>
                          <div className="col-6">
                            <h5>Completed Degree</h5>
                            <p className="mb-3">
                              {studentData.completed_degree}
                            </p>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-6">
                            <h5>Expertise</h5>
                            <p className="mb-3">{studentData.expertise}</p>
                          </div>
                          <div className="col-6">
                            <h5>Passout Year</h5>
                            <p className="mb-3">{studentData.passout_year}</p>
                          </div>
                        </div>

                        <hr></hr>

                        <div className="row mb-4">
                          <div className="col-6">
                            <h5>Account Number</h5>
                            <p className="mb-3">{studentData.account_number}</p>

                            <h5>Bank Name</h5>
                            <p className="mb-3">{studentData.bank_name}</p>

                            <h5>PAN Number</h5>
                            <p className="mb-3">{studentData.pan_number}</p>
                          </div>
                          <div className="col-6">
                            <h5>IFSC code</h5>
                            <p className="mb-3">{studentData.ifsc_code}</p>

                            <h5>Branch</h5>
                            <p className="mb-3">{studentData.passout_year}</p>

                            <h5>Salary</h5>
                            <p className="mb-3">{studentData.base_amount}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : (
                  <div className="alert alert-info mt-3" role="alert">
                    Enter an ERP number and click "Get Details" to fetch staff
                    data.
                  </div>
                )}
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Staffprofile;
