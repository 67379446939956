import React from "react";
import Sidenav from "../../../Pages/nav/Sidenav";
import Footer from "../../footer/Footer";
import Topnav from "../../../Pages/nav/Topnav";

function Managetc() {
  return (
    <div className="nav-fixed">
      <Topnav />
      <div id="layoutSidenav">
        <div>
          <Sidenav />
        </div>
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid p-3">
                <div className="row">
                  <form className="row g-2 form-inline">
                    <div className="col-auto">
                      <input
                        type="text"
                        className="form-control"
                        name="erp"
                        placeholder="Enter ERP Number"
                        value=""
                        required
                      />
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn btn-theme2"
                        type="submit"
                        id="getuserdetail"
                      >
                        Get Details
                      </button>
                    </div>
                    <div className="col-auto mt-3 editbutton">
                      <a className="textbutton color2  text-white" href=" ">
                        Edit Profile
                      </a>
                    </div>
                  </form>
                </div>
                <section
                  className="usersprofile mt-5 wow fadeInLeft"
                  id="usersprofile"
                >
                  <div className="row mt-5">
                    <div className="iconbox color2 up10">
                      <div className="iconval wow shake">
                        <i className="fa-solid fa-file-pen"></i>
                      </div>
                    </div>
                    <div className="heading">
                      <h4>Manage TC</h4>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-12 ">
                      <h6> ERP Number:</h6>
                    </div>
                    <div className="col-12 col-md-6 ">
                      <h6>TC No:</h6>
                      <h6>Admission No:</h6>
                      <h6>Student Name:</h6>
                      <h6>Gender:</h6>
                      <h6>DOB:</h6>
                      <h6>Nationality:</h6>
                      <h6>Character & Contact:</h6>
                      <h6>Last Date of the Attendance:</h6>
                      <h6>Prepared by:</h6>
                    </div>
                    <div className="col-12 col-md-6">
                      <h6>Father's Name:</h6>
                      <h6>Mother's Name:</h6>
                      <h6>Mother tongue:</h6>
                      <h6>Date of Admission:</h6>
                      <h6>Admitted Grade:</h6>
                      <h6>Present Grade:</h6>
                      <h6>End of the AY:</h6>
                      <h6>Date of issue of TC:</h6>
                    </div>
                  </div>
                </section>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Managetc;
