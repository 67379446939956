import React, { useState, useEffect } from "react";
import WOW from "wowjs";
import Topnav from "../../../Pages/nav/Topnav";
import Sidenav from "../../../Pages/nav/Sidenav";
import Footer from "../../footer/Footer";
import axios from "axios";
import API from "../../../config/index";
import { retrieveAuthToken } from "../../../api/authentication";

function ListStudents() {
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const [classes, setClasses] = useState([]);
    const [students, setStudents] = useState([]);
    const [selectedClass, setSelectedClass] = useState("");
    const [error, setError] = useState("");
    const token = retrieveAuthToken();

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const response = await axios.get(`${API}/classroom/`, {
                    headers: {
                        Authorization: token,
                        schoolId: process.env.REACT_APP_SCHOOL_ID,
                    },
                });
                setClasses(response.data);
            } catch (error) {
                setError("Error fetching classes");
                console.error("Error fetching classes:", error);
            }
        };

        fetchClasses();
    }, [token]);

    useEffect(() => {
        if (selectedClass) {
            const fetchStudents = async () => {
                try {
                    const response = await axios.get(`${API}/students/class/all/${selectedClass}`, {
                        headers: {
                            Authorization: token,
                            schoolId: process.env.REACT_APP_SCHOOL_ID,
                        },
                    });
                    setStudents(response.data);

                } catch (error) {
                    setError("Error fetching students");
                    console.error("Error fetching students:", error);
                }
            };

            fetchStudents();
        }
    }, [selectedClass, token]);


    const handleClassChange = (event) => {
        setSelectedClass(event.target.value);
        setStudents([]); // Reset students when a new class is selected
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const localOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
        const localDate = new Date(date.getTime() + localOffset);
        return localDate.toISOString().split('T')[0];
    };

    return (
        <div className="nav-fixed">
            <Topnav toggleSidebar={toggleSidebar} />
            <div id="layoutSidenav">
                {isSidebarVisible && (
                    <div>
                        <Sidenav />
                    </div>
                )}
                <div id="layoutSidenav_content">
                    <div>
                        <main>
                            <div className="container-fluid p-3">
                                <h1 className="mt-4 mb-2">List Students</h1>
                                <section>
                                    <div className="row mt-5 mb-5">
                                        <div className="iconbox color1 up10">
                                            <div className="iconval wow shake">
                                                <i className="fa-solid fa-user-graduate"></i>
                                            </div>
                                        </div>
                                        <div className="heading">
                                            <h4>List of Students</h4>
                                        </div>
                                    </div>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <div className="row mb-4">
                                        <div className="col-6">
                                            <label className="form-label">Class</label>
                                            <select
                                                className="form-select"
                                                value={selectedClass}
                                                onChange={handleClassChange}
                                            >
                                                <option value="">- Select Class -</option>
                                                {classes.map((classItem) => (
                                                    <option key={classItem.id} value={classItem.id}>
                                                        {classItem.class} {classItem.section}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {students.length > 0 && (
                                        <div className="row">
                                            <table className="display responsive nowrap" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>ERP Number</th>
                                                        <th>Full Name</th>
                                                        <th>Date of Birth</th>
                                                        <th>Phone</th>
                                                        <th>Religion</th>
                                                        <th>Caste</th>
                                                        <th>Father's Name</th>
                                                        <th>Mother's Name</th>
                                                        <th>Aadhar Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {students.map((student) => (
                                                        <tr key={student.erp_num}>
                                                            <td>{student.erp_num}</td>
                                                            <td>{student.name}</td>
                                                            <td>{formatDate(student.dob)}</td>
                                                            <td>{student.primary_phone}</td>
                                                            <td>{student.religion}</td>
                                                            <td>{student.caste}</td>
                                                            <td>{student.fathers_name}</td>
                                                            <td>{student.mothers_name}</td>
                                                            <td>{student.aadhar_no}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    )}
                                </section>
                            </div>
                        </main>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListStudents;
