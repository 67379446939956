import axios from "axios";
import API from "../config/index";
import { retrieveAuthToken } from "./authentication";

const token = retrieveAuthToken();

const Student = {
  getStudent: async (classId) => {
    try {
      const response = await axios.get(`${API}/students/`, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching student data:", error);
      throw error;
    }
  },

  addStudent: async (studentsData) => {
    try {
      const response = await axios.post(
        `${API}/students/addStudent`,
        studentsData,
        {
          headers: {
            Authorization: token,
            schoolId: process.env.REACT_APP_SCHOOL_ID,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating student:", error);
      throw error;
    }
  },
};

export default Student;
