import React, { useState, useEffect } from "react";
import Sidenav from "../../../Pages/nav/Sidenav";
import Footer from "../../footer/Footer";
import Topnav from "../../../Pages/nav/Topnav";
import createparcel from "../../../api/Administration/parcel/parcel";

function Parcel() {
  const [parcelData, setParcelData] = useState({
    parcelto: "",
    name: "",
    class: "",
    parcel: "",
    image: null,
    stime: "",
  });
  const [parcelLogs, setParcelLogs] = useState([]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setParcelData({ ...parcelData, [name]: value });
  };

  const handleFileChange = (e) => {
    setParcelData({ ...parcelData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in parcelData) {
      formData.append(key, parcelData[key]);
    }

    try {
      const response = await createparcel.addparcel(formData);
      setParcelLogs([...parcelLogs, response]); // Update the table with the new entry
      // Reset form
      setParcelData({
        parcelto: "",
        name: "",
        class: "",
        parcel: "",
        image: null,
        stime: "",
      });
    } catch (error) {
      console.error("Error adding parcel:", error);
    }
  };

  useEffect(() => {
    // Fetch existing parcel logs from the API if needed
    // Example: setParcelLogs(response.data);
  }, []);

  return (
    <div className="nav-fixed">
      <Topnav />
      <div id="layoutSidenav">
        <div>
          <Sidenav />
        </div>
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid p-3">
                <h1 className="mt-4 mb-2">Parcel Entry</h1>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <section>
                      <div className="row mt-5">
                        <h5 className="mt-5">
                          <u>Enter Parcel Details</u>
                        </h5>
                        <form className="row g-3" onSubmit={handleSubmit}>
                          <div className=" col-12">
                            <label className="form-label">
                              Parcel To <span>*</span>
                            </label>
                            <select
                              className="form-select"
                              name="parcelto"
                              value={parcelData.parcelto}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="" disabled>
                                - Select -
                              </option>
                              <option value="Student">Student</option>
                              <option value="Staff">Staff</option>
                            </select>
                          </div>

                          <div className=" col-12">
                            <label className="form-label">
                              Name <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={parcelData.name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>

                          <div className=" col-12">
                            <label className="form-label">Class</label>
                            <input
                              type="text"
                              className="form-control"
                              name="class"
                              value={parcelData.class}
                              onChange={handleInputChange}
                            />
                          </div>

                          <div className=" col-12">
                            <label className="form-label">
                              Parcel Details <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="parcel"
                              value={parcelData.parcel}
                              onChange={handleInputChange}
                              required
                            />
                          </div>

                          <div className=" col-12">
                            <label className="form-label">Image</label>
                            <input
                              type="file"
                              className="form-control"
                              name="image"
                              onChange={handleFileChange}
                            />
                          </div>

                          <div className=" col-12">
                            <label className="form-label">
                              Entry Time <span>*</span>
                            </label>
                            <input
                              type="time"
                              className="form-control"
                              name="stime"
                              value={parcelData.stime}
                              onChange={handleInputChange}
                              required
                            />
                          </div>

                          <div className="col-12">
                            <button className="btn btn-theme2" type="submit">
                              Create Details
                            </button>
                          </div>
                        </form>
                      </div>
                    </section>
                  </div>
                  <div className="col-12 col-md-8">
                    <section>
                      <div className="row mt-5 mb-5">
                        <div className="iconbox color2 up10">
                          <div className="iconval wow shake">
                            <i className="fas fa-box"></i>
                          </div>
                        </div>
                        <div className="heading">
                          <h4>Parcel Log</h4>
                        </div>
                      </div>
                      <div className="row">
                        <table className="display" style={{ width: "100%" }}>
                          <thead>
                            <tr>
                              <th>Id</th>
                              <th>Person</th>
                              <th>Parcel</th>
                              <th>Image</th>
                              <th>Date and Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {parcelLogs.map((log, index) => (
                              <tr key={index}>
                                <td>{log.id}</td>
                                <td>{log.person}</td>
                                <td>{log.parcel}</td>
                                <td>
                                  {log.image && (
                                    <img
                                      src={URL.createObjectURL(log.image)}
                                      alt="parcel"
                                      width="50"
                                      height="50"
                                    />
                                  )}
                                </td>
                                <td>{log.dateTime}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Parcel;
