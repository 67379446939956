import React, { useState, useEffect } from "react";
import Sidenav from "../../../Pages/nav/Sidenav";
import Footer from "../../footer/Footer";
import Topnav from "../../../Pages/nav/Topnav";
import { retrieveAuthToken } from "../../../api/authentication";
import API from "../../../config";

const token = retrieveAuthToken();

function BulkUpload() {
    const [classList, setClassList] = useState([]);
    const [selectedClass, setSelectedClass] = useState("");
    const [file, setFile] = useState(null);

    // Fetch class list from API
    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const response = await fetch(`${API}/classroom/`, {
                    headers: {
                        'Authorization': token,
                        schoolId: process.env.REACT_APP_SCHOOL_ID,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setClassList(data);
                } else {
                    console.error('Failed to fetch class list');
                }
            } catch (error) {
                console.error('Error fetching class list:', error);
            }
        };
        fetchClassList();
    }, []);

    // Handle file selection
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    // Handle file upload
    const handleFileUpload = async (event) => {
        event.preventDefault();

        if (!file) {
            alert('Please select a file.');
            return;
        }

        if (!selectedClass) {
            alert('Please select a class.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(`${API}/files/addfiles/${selectedClass}`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    schoolId: process.env.REACT_APP_SCHOOL_ID,
                },
                body: formData
            });
            if (response.ok) {
                alert('File uploaded successfully!');
                // Reset form or perform any other necessary actions
                setFile(null);
                setSelectedClass("");
            } else {
                console.error('Failed to upload file.');
                alert('Failed to upload file. Please try again.');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file. Please try again.');
        }
    };

    return (
        <div className="nav-fixed">
            <Topnav />
            <div id="layoutSidenav">
                <div>
                    <Sidenav />
                </div>
                <div id="layoutSidenav_content">
                    <div>
                        <main>
                            <div className="container-fluid p-3">
                                <h1 className="mt-4 mb-2">Medical Condition Details</h1>
                                <div className="row">
                                    <div className="col-12 text-end">
                                        <form onSubmit={handleFileUpload} className="d-flex align-items-center">
                                            <label htmlFor="upload" className="textbutton color6 text-white me-3"><i className="fa-solid fa-upload"></i> Bulk Upload</label>
                                            <input type="file" id="upload" className="d-none" onChange={handleFileChange} />
                                            <select className="form-select me-3" onChange={(e) => setSelectedClass(e.target.value)} value={selectedClass}>
                                                <option value="">Select Class</option>
                                                {classList.map((classItem) => (
                                                    <option key={classItem.id} value={classItem.id}>{classItem.class} {classItem.section}</option>
                                                ))}
                                            </select>
                                            <button type="submit" className="btn btn-theme2">Upload</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BulkUpload;
