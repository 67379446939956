import React, { useEffect } from "react";
import Sidenav from "../../../Pages/nav/Sidenav";
import Footer from "../../footer/Footer";
import WOW from "wowjs";
import Topnav from "../../../Pages/nav/Topnav";

function Payrolldetails() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    wow.sync();
  }, []);
  return (
    <div className="nav-fixed">
      <Topnav />
      <div  id="layoutSidenav">
        <div>
          <Sidenav />
        </div>
        <div  id="layoutSidenav_content">
          <div >
            <main >
              <div className="container-fluid p-3">
                <h1 className="mt-4 mb-2">Payroll Details</h1>

                <section className="wow fadeInLeft">
                  <div className="row mt-5">
                    <div className="iconbox color2 up10">
                      <div className="iconval wow shake">
                        <i className="fa-solid fa-file-invoice-dollar"></i>
                      </div>
                    </div>
                    <div className="heading">
                      <h4> Enter Payroll Details</h4>
                    </div>
                  </div>
                  <div className="row">
                    <form className="row g-4">
                      <div className="col-12 col-md-6">
                        <label className="form-label">
                          ERP Number <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="erpnumber"
                          required
                        />
                      </div>
                      <div className=" col-12 col-md-6">
                        <label className="form-label">
                          Name <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="username"
                          required
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label">
                          Month <span>*</span>
                        </label>
                        <select className="form-select" name="month">
                          <option selected> - Select - </option>
                          <option value="January">Jan</option>
                          <option value="February">Feb</option>
                          <option value="March">Mar</option>
                          <option value="April">Apr</option>
                          <option value="May">May</option>
                          <option value="June">Jun</option>
                          <option value="July">Jul</option>
                          <option value="August">Aug</option>
                          <option value="September">Sep</option>
                          <option value="October">Oct</option>
                          <option value="November">Nov</option>
                          <option value="December">Dec</option>
                        </select>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label">
                          Working days <span>*</span>
                        </label>
                        <select className="form-select" name="working">
                          <option selected> - Select - </option>
                          <option value="">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                        </select>
                      </div>
                      <div className="col-12 ">
                        <label className="form-label">
                          Annual Salary <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="annualsalary"
                          required
                        />
                      </div>
                      <div className=" col-12 col-md-6">
                        <label className="form-label">
                          Basic Salary <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="basicsalary"
                          required
                        />
                      </div>
                      <div className=" col-12 col-md-6">
                        <label className="form-label">
                          Basic value type <span>*</span>
                        </label>
                        <select className="form-select" name="valuetype">
                          <option selected> - Select - </option>
                          <option value="Fixed">Fixed</option>
                          <option value="CTC%">CTC%</option>
                        </select>
                      </div>
                      <div className="col-12 col-md-4 col-lg-3">
                        <label className="form-label">
                          TA <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="ta"
                          value=""
                          required
                        />
                      </div>
                      <div className="col-12 col-md-4 col-lg-2">
                        <label className="form-label">
                          CA <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="ca"
                          value=""
                          required
                        />
                      </div>
                      <div className="col-12 col-md-4 col-lg-2 ">
                        <label className="form-label">
                          MA <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="ma"
                          value=""
                          required
                        />
                      </div>
                      <div className="col-12 col-md-4 col-lg-2 ">
                        <label className="form-label">
                          LTA <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="lta"
                          value=""
                          required
                        />
                      </div>
                      <div className="col-12 col-md-4 col-lg-3">
                        <label className="form-label">
                          SA <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="sa"
                          value=""
                          required
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label">
                          Gross Salary <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="gross"
                          value=""
                          required
                        />
                      </div>

                      <div className="col-12 col-md-6">
                        <label className="form-label">
                          Net Salary <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="net"
                          value=""
                          required
                        />
                      </div>

                      <div className="col-12">
                        <button className="btn btn-theme2" type="submit">
                          Save Payroll
                        </button>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payrolldetails;
