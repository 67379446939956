import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidenav from "../../Pages/nav/Sidenav";
import Footer from "../footer/Footer";
import Topnav from "../../Pages/nav/Topnav";
import API from "../../config/index";
import { retrieveAuthToken } from "../../api/authentication";

function Announcement() {
  const [classrooms, setClassrooms] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const token = retrieveAuthToken();

  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const response = await axios.get(`${API}/classroom/`, {
          headers: {
            Authorization: token,
            schoolId: process.env.REACT_APP_SCHOOL_ID,
          },
        });
        setClassrooms(response.data);
      } catch (error) {
        console.error("Error fetching classroom data:", error);
      }
    };

    fetchClassrooms();
  }, [token]);

  const handleClassChange = async (event) => {
    const classId = event.target.value;
    setSelectedClassId(classId);

    try {
      const response = await axios.get(`${API}/students/phone/class`, {
        params: { classId },
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });
      setPhoneNumbers(response.data);
    } catch (error) {
      console.error("Error fetching phone numbers:", error);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedClassId || !selectedFile || !phoneNumbers) {
      alert("Please select a class, upload a file, and ensure phone numbers are available.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("classId", selectedClassId);
    formData.append("phoneNumber", phoneNumbers);

    try {
      const response = await axios.post(`${API}/comm/homework/`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });

      alert("Announcement sent successfully!");
    } catch (error) {
      console.error("Error sending announcement:", error);
      alert("Failed to send the announcement. please try again after sometime");
    }
  };

  return (
    <div className="nav-fixed">
      <Topnav />
      <div id="layoutSidenav">
        <div>
          <Sidenav />
        </div>
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid p-3">
                <h1 className="mt-4 mb-2">Create Announcement</h1>
                <section>
                  <div className="row mt-5">
                    <div className="iconbox color2 up10">
                      <div className="iconval wow shake">
                        <i className="fa-solid fa-bullhorn"></i>
                      </div>
                    </div>
                    <div className="heading">
                      <h4>Create Announcement</h4>
                    </div>
                  </div>
                  <div className="row">
                    <form className="row g-4" onSubmit={handleSubmit}>
                      <div className="col-12 col-md-12">
                        <label className="form-label">Class</label>
                        <select
                          className="form-select"
                          name="class"
                          required
                          onChange={handleClassChange}
                          value={selectedClassId}
                        >
                          <option value="" disabled>
                            - Select -
                          </option>
                          {classrooms.map((classroom) => (
                            <option key={classroom.id} value={classroom.id}>
                              {classroom.class} {classroom.section}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12">
                        <label className="form-label">Upload Document</label>
                        <input
                          type="file"
                          className="form-control"
                          name="announcement"
                          onChange={handleFileChange}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <button className="btn btn-theme2" type="submit">
                          Send Announcement
                        </button>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Announcement;
