import React from "react";
import Routing from "./Route/Routing";

function App() {
  return (
    <Routing />
  );
}

export default App;
