import React from "react";
import Sidenav from "../nav/Sidenav";
import Footer from "../../views/footer/Footer";

function Resetpassword() {
    return (
        <div id="layoutSidenav"><Sidenav />
            <div id="layoutSidenav_content">
                <main>
                    <div className="container-fluid px-4">

                        <section className="wow fadeInLeft">
                            <div className="row mt-5">
                                <div className="iconbox color2 up10">
                                    <div className="iconval wow shake"><i className="fa-solid fa-key"></i></div>
                                </div>
                                <div className="heading">
                                    <h4> Reset Password</h4>
                                </div>
                            </div>
                            <div className="row">
                                <form className="row g-4">
                                    <div className="col-12">
                                        <label className="form-label">Current Password <span>*</span></label>
                                        <input type="password" className="form-control" name="name" value="" required />
                                    </div>
                                    <div className=" col-12">
                                        <label className="form-label">New Password <span>*</span></label>
                                        <input type="password" className="form-control" name="username" value="" required />
                                    </div>
                                    <div className="col-12">
                                        <label className="form-label">Confirm New Password <span>*</span></label>
                                        <input type="password" className="form-control" name="password" value="" required />
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-theme2" type="submit">Reset Password</button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    )

}

export default Resetpassword;