import React, { useEffect, useState } from "react";
import Sidenav from "../../Pages/nav/Sidenav";
import Footer from "../footer/Footer";
import WOW from "wowjs";
import Topnav from "../../Pages/nav/Topnav";
import Classroom from "../../api/classroom"; // Make sure the import path is correct
import Subject from "../../api/subject"; // Make sure the import path is correct
import TimetableApi from "../../api/timetable"; // Make sure the import path is correct

function Timetable() {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [classrooms, setClassrooms] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [timetable, setTimetable] = useState(null); // State to hold timetable data
  const [formData, setFormData] = useState({
    class_id: "",
    teacher_id: "",
    day_of_week: "",
    period: "",
    subject_id: "",
    start_time: "",
    end_time: "",
  });

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();

    const fetchClassrooms = async () => {
      try {
        const classroomData = await Classroom.getClassroom();
        setClassrooms(classroomData);
      } catch (error) {
        console.error("Error fetching classroom data:", error);
      }
    };

    fetchClassrooms();
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "class_id") {
      fetchSubjects(value);
      fetchTimetable(value); // Fetch timetable when class is selected
    } else if (name === "subject_id") {
      const selectedSubject = subjects.find(
        (subject) => subject.id === parseInt(value)
      );
      setFormData({
        ...formData,
        subject_id: value,
        teacher_id: selectedSubject ? selectedSubject.incharge_id : "",
      });
    }
  };

  const fetchSubjects = async (classId) => {
    try {
      const subjectData = await Subject.getSubjectsbyClass(classId);
      setSubjects(subjectData);
    } catch (error) {
      console.error("Error fetching subject data:", error);
    }
  };

  const fetchTimetable = async (classId) => {
    try {
      const timetabledata = await TimetableApi.fetchTimetable(classId);
      // Transform the timetable data
      const transformedTimetable = timetabledata.timetable.reduce(
        (acc, item) => {
          if (!acc[item.day_of_week]) {
            acc[item.day_of_week] = {};
          }
          acc[item.day_of_week][item.period] = item;
          return acc;
        },
        {}
      );

      setTimetable(transformedTimetable);
    } catch (error) {
      console.error("Error fetching timetable data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await TimetableApi.createTimeTable(formData);
      alert("Timetable allocated successfully!");
      // Optionally, reset the form or update the state to reflect changes
    } catch (error) {
      console.error("Error allocating timetable:", error);
      alert("Failed to allocate timetable.");
    }
  };

  return (
    <div className="nav-fixed">
      <Topnav toggleSidebar={toggleSidebar} />
      <div id="layoutSidenav">
        {isSidebarVisible && (
          <div>
            <Sidenav />
          </div>
        )}
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid p-3">
                <h1 className="mt-4 mb-2">Timetable</h1>

                <div className="row ms-2">
                  <form className="row g-4 mb-5" onSubmit={handleSubmit}>
                    <section className="wow fadeInLeft">
                      <div className="row mb-5">
                        <div className="iconbox color2 up10">
                          <div className="iconval wow shake">
                            <i className="fa-regular fa-calendar-days"></i>
                          </div>
                        </div>
                        <div className="heading">
                          <h4>Timetable</h4>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row g-4 mb-5">
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              Days<span>*</span>
                            </label>
                            <select
                              className="form-select"
                              name="day_of_week"
                              value={formData.day_of_week}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="" disabled>
                                - Select -
                              </option>
                              <option value="Monday">Monday</option>
                              <option value="Tuesday">Tuesday</option>
                              <option value="Wednesday">Wednesday</option>
                              <option value="Thursday">Thursday</option>
                              <option value="Friday">Friday</option>
                              <option value="Saturday">Saturday</option>
                            </select>
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              Period<span>*</span>
                            </label>
                            <select
                              className="form-select"
                              name="period"
                              value={formData.period}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="" disabled>
                                - Select -
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="Short Break">Short Break</option>
                              <option value="Lunch Break">Lunch Break</option>
                            </select>
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              Class<span>*</span>
                            </label>
                            <select
                              className="form-select"
                              name="class_id"
                              value={formData.class_id}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="" disabled>
                                - Select -
                              </option>
                              {classrooms.map((classroom) => (
                                <option key={classroom.id} value={classroom.id}>
                                  {classroom.class + " " + classroom.section}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              Subject<span>*</span>
                            </label>
                            <select
                              className="form-select"
                              name="subject_id"
                              value={formData.subject_id}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="" disabled>
                                - Select -
                              </option>
                              {subjects.map((subject) => (
                                <option key={subject.id} value={subject.id}>
                                  {subject.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              Start Time<span>*</span>
                            </label>
                            <input
                              type="time"
                              className="form-control"
                              name="start_time"
                              value={formData.start_time}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              End Time<span>*</span>
                            </label>
                            <input
                              type="time"
                              className="form-control"
                              name="end_time"
                              value={formData.end_time}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <button type="submit" className="btn btn-primary">
                              Allocate Timetable
                            </button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </form>

                  <section>
                    <div className="row mb-5">
                      <div className="iconbox color2 up10">
                        <div className="iconval wow shake">
                          <i className="fa-regular fa-calendar-days"></i>
                        </div>
                      </div>
                      <div className="heading">
                        <h4>Class Timetable List</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <label className="form-label">
                          Select Class to View Timetable
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => fetchTimetable(e.target.value)}
                        >
                          <option value="" disabled selected>
                            - Select -
                          </option>
                          {classrooms.map((classroom) => (
                            <option key={classroom.id} value={classroom.id}>
                              {classroom.class + " " + classroom.section}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {timetable && (
                      <div className="row mt-4">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Day / Period</th>
                              {[...Array(10).keys()].map((i) => (
                                <th key={i + 1}>{i + 1}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                            ].map((day) => (
                              <tr key={day}>
                                <td>{day}</td>
                                {[...Array(10).keys()].map((i) => (
                                  <td key={i + 1}>
                                    {timetable[day] && timetable[day][i + 1]
                                      ? timetable[day][i + 1].subject_id
                                      : ""}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </section>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timetable;
