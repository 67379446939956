import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/dash.css';

function Topnav({ toggleSidebar }) {
  return (
    <div>
      <nav className="topnav navbar navbar-expand sidenav-dark py-0 fixed-top">
        <Link className="navbar-brand ps-3" to="/dashboard">
          <img src="../assets/img/sitelogo.png" className="img-fluid" alt="Site Logo" />
        </Link>
        {/* <button
          className="btn btn-link btn-sm order-0 order-lg-0 text-light"
          id="sidebarToggle"
          style={{ fontSize: "18px" }}
          onClick={toggleSidebar}
          aria-label="Toggle sidebar"
        >
          <i className="fas fa-bars nav-link"></i>
        </button> */}
        <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0"></form>
        {/* <ul className="navbar-nav me-auto ms-md-0 me-3 me-lg-4">
          <li className="nav-item mx-2">
            <Link className="nav-link text-light" to="/contactteam">
              <i className="fas fa-envelope"></i>
            </Link>
          </li>
          <li className="mx-2 dropdown">
            <a
              className="nav-link text-light"
              id="navbarDropdown"
              href=" "
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa-solid fa-bell"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <li><a className="dropdown-item" href=" "><i className="fa fa-clock"></i> new user added by admin2</a></li>
              <hr className="dropdown-divider" />
              <li><a className="dropdown-item" href=" "><i className="fa fa-clock"></i> new user added by admin2</a></li>
              <hr className="dropdown-divider" />
              <li><a className="dropdown-item" href=" "><i className="fa fa-clock"></i> new user added by admin2</a></li>
              <hr className="dropdown-divider" />
              <li><a className="dropdown-item" href=" "><i className="fa fa-clock"></i> new user added by admin2</a></li>
            </ul>
          </li>
          <li className="nav-item mx-2">
            <a
              className="nav-link text-light"
              id="navbarDropdown"
              href=" "
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="../assets/img/admin.jpg" className="d-block" alt="admin" width={24} height={24} />
            </a>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <li><Link className="dropdown-item" to="/viewprofile"><i className="far fa-user"></i> View Profile</Link></li>
              <li><Link className="dropdown-item" to="/resetpassword"><i className="fas fa-key"></i> Reset Password</Link></li>
              <li><hr className="dropdown-divider" /></li>
              <li><Link className="dropdown-item" to="/logout"><i className="fas fa-right-from-bracket"></i> Logout</Link></li>
            </ul>
          </li>
        </ul> */}
      </nav>
    </div>
  );
}

export default Topnav;
