import React, { useState, useEffect } from "react";
import WOW from "wowjs";
import Topnav from "../../../Pages/nav/Topnav";
import Sidenav from "../../../Pages/nav/Sidenav";
import Footer from "../../footer/Footer";
import axios from "axios";
import API from "../../../config/index";
import { retrieveAuthToken } from "../../../api/authentication";

function Studentattendance() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const token = retrieveAuthToken();

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get(`${API}/classroom/`, {
          headers: {
            Authorization: token,
            schoolId: process.env.REACT_APP_SCHOOL_ID,
          },
        });
        setClasses(response.data);
      } catch (error) {
        setError("Error fetching classes");
        console.error("Error fetching classes:", error);
      }
    };

    fetchClasses();
  }, [token]);

  useEffect(() => {
    if (selectedClass) {
      const fetchStudents = async () => {
        try {
          const response = await axios.get(`${API}/students/class/${selectedClass}`, {
            headers: {
              Authorization: token,
              schoolId: process.env.REACT_APP_SCHOOL_ID,
            },
          });
          setStudents(response.data);
        } catch (error) {
          setError("Error fetching students");
          console.error("Error fetching students:", error);
        }
      };

      fetchStudents();
    }
  }, [selectedClass, token]);

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    setSelectedStudents([]);
  };

  const handleStudentSelection = (event) => {
    const studentId = parseInt(event.target.value);
    const isChecked = event.target.checked;

    if (isChecked) {
      const selectedStudent = students.find((student) => student.id === studentId);
      if (selectedStudent) {
        setSelectedStudents((prev) => [...prev, selectedStudent]);
      }
    } else {
      setSelectedStudents((prev) =>
        prev.filter((student) => student.id !== studentId)
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Confirm before submission
    const isConfirmed = window.confirm("Confirm attendance?");
    if (!isConfirmed) return;

    setIsSubmitting(true);
    try {
      // Prepare the data for students/attendance
      const attendanceData = selectedStudents.map((student) => ({
        id: student.id,
        attendance: student.attendance,
      }));

      // Send attendance data
      const attendanceResponse = await axios.post(`${API}/students/attendance`, { students: attendanceData }, {
        headers: {
          Authorization: token,
          schoolId: process.env.REACT_APP_SCHOOL_ID,
        },
      });

      // Check if the response contains data
      if (!Array.isArray(attendanceData) || attendanceData.length === 0) {
        throw new Error("No students selected for attendance");
      }

      // Prepare the data for comm/attendance
      const commData = selectedStudents.map((student) => ({
        phone: student.phone,  // Ensure phone data is available in student object
        name: student.name,
        leaveType: student.attendance,
      }));

      // After successful attendance submission, call the communication API
      const commResponses = await Promise.all(commData.map(async (data) => {
        try {
          const commResponse = await axios.post(`${API}/comm/attendance`, { students: [data] }, {
            headers: {
              Authorization: token,
              schoolId: process.env.REACT_APP_SCHOOL_ID,
            },
          });

          return commResponse.data; // Collect responses for each API call
        } catch (error) {
          console.error(`Error calling comm/attendance for ${data.name}:`, error);
          return null; // Handle errors as needed
        }
      }));

      console.log("Communication API responses:", commResponses);

      setSelectedStudents([]);
      setIsSubmitting(false);
    } catch (error) {
      setError("Error saving attendance");
      console.error("Error saving attendance:", error);
      setIsSubmitting(false);
    }
  };


  return (
    <div className="nav-fixed">
      <Topnav toggleSidebar={toggleSidebar} />
      <div id="layoutSidenav">
        {isSidebarVisible && (
          <div>
            <Sidenav />
          </div>
        )}
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid p-3">
                <h1 className="mt-4 mb-2">Student Attendance</h1>
                <section>
                  <div className="row mt-5 mb-5">
                    <div className="iconbox color1 up10">
                      <div className="iconval wow shake">
                        <i className="fa-solid fa-clipboard-check"></i>
                      </div>
                    </div>
                    <div className="heading">
                      <h4>Student Attendance</h4>
                    </div>
                  </div>
                  {error && <div className="alert alert-danger">{error}</div>}
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-4">
                      <div className="col-6">
                        <label className="form-label">Class</label>
                        <select className="form-select" value={selectedClass} onChange={handleClassChange}>
                          <option value="">- Select Class -</option>
                          {classes.map((classItem) => (
                            <option key={classItem.id} value={classItem.id}>
                              {classItem.class} {classItem.section}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-6">
                        <label className="form-label">Students</label>
                        <div className="form-check">
                          {students.map((student) => (
                            <div key={student.id}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={student.id}
                                onChange={handleStudentSelection}
                              />
                              <label className="form-check-label">
                                {student.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <table className="display responsive nowrap" style={{ width: "100%" }}>
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>ERP ID</th>
                            <th>Name</th>
                            <th>Attendance</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedStudents.map((student, index) => (
                            <tr key={student.id}>
                              <td>{student.id}</td>
                              <td>{student.erp_num}</td>
                              <td>{student.name}</td>
                              <td>
                                <select
                                  className="form-select w-50"
                                  value={student.attendance || ''}
                                  onChange={(e) => {
                                    const updatedStudents = [...selectedStudents];
                                    updatedStudents[index].attendance = e.target.value;
                                    setSelectedStudents(updatedStudents);
                                  }}
                                >
                                  <option value="">- Select -</option>
                                  <option value="Absent">Absent</option>
                                  <option value="OD">OD</option>
                                  <option value="Halfday AM">Halfday AM</option>
                                  <option value="Halfday PM">Halfday PM</option>
                                </select>
                              </td>
                              <td className="text-center">
                                <a href="# " className="tablebutton bg-primary text-white">
                                  <i className="fas fa-edit"></i>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-12 mt-5">
                      <button className="btn btn-theme2" type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Saving..." : "Save Student Attendance"}
                      </button>
                    </div>
                  </form>
                </section>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Studentattendance;
