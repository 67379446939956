import React from "react";
import Sidenav from "../../../Pages/nav/Sidenav";
import Footer from "../../footer/Footer";
import Topnav from "../../../Pages/nav/Topnav";

function Medicalcondition() {
    return (
        <div className="nav-fixed">
            <Topnav />

            <div  id="layoutSidenav">
                <div >
                    <Sidenav />
                </div>
                <div  id="layoutSidenav_content">
                <div >
                <main>
                    <div className="container-fluid p-3">
                        <h1 className="mt-4 mb-2">Medical Condition Details</h1>
                        <div className="row">
                            <div className="col-12 text-end">
                                <a className="textbutton color6 text-white" href="sheet/addadmin.xlsx" target="blank" id="bulk">Bulk Upload</a>
                                <a className="textbutton color6 text-white hideclass" href="sheet/addadmin.xlsx" id="upload"><i className="fa-solid fa-upload"></i> Bulk Upload</a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4">
                                <section className="wow fadeInLeft">
                                    {/* <!-- <div class="row mt-5">
                                        <div class="iconbox color2 up10">
                                            <div class="iconval wow shake"><i class="fa-solid fa-user-shield"></i></div>
                                        </div>
                                        <div class="heading">
                                            <h4> Enter Details</h4>
                                        </div>
                                    </div> --> */}
                                    <div className="row">
                                        <form className="row g-3">
                                            <h5><u>Enter the details</u></h5>
                                            <div className="col-auto">
                                                <label className="form-label">Name <span>*</span></label>
                                                <input type="text" className="form-control" name="name" value="" required />
                                            </div>
                                            <div className=" col-auto">
                                                <label className="form-label">Erp Number <span>*</span></label>
                                                <input type="text" className="form-control" name="erpnumber" value="" required />
                                            </div>
                                            <div className="col-auto">
                                                <label className="form-label">Blood Group<span>*</span></label>
                                                <input type="text" className="form-control" name="blood" value="" required />
                                            </div>
                                            <div className="col-auto">
                                                <label className="form-label">Illness Type <span>*</span></label>
                                                <input type="text" className="form-control" name="illness" value="" required />
                                            </div>
                                            <div className="col-auto">
                                                <label className="form-label">Illness Details <span>*</span></label>
                                                <textarea name="illness" className="form-control"></textarea>
                                            </div>
                                            <div className="col-auto">
                                                <label className="form-label">Medicine Details <span>*</span></label>
                                                <textarea name="illness" className="form-control"></textarea>
                                            </div>

                                            <div className="col-auto">
                                                <button className="btn btn-theme2" type="submit">Enter Info</button>
                                            </div>
                                        </form>
                                    </div>
                                </section>
                            </div>
                            <div className="col-12 col-md-6 col-lg-8">
                                <section className="wow fadeInLeft">
                                    <div className="row mt-5">
                                        <div className="iconbox color2 up10">
                                            <div className="iconval wow shake"><i className="fas fa-pills"></i></div>
                                        </div>
                                        <div className="heading">
                                            <h4>Medical Condition</h4>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <table id="" className="display responsive nowrap" style={{width : "100%"}}>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Erp</th>
                                                    <th>Blood</th>
                                                    <th>Illness</th>
                                                    <th>Medicine</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="text-center"><a href=" " className="tablebutton bg-primary text-white"><i className="fas fa-edit"></i></a>
                                                        <a href=" " className="tablebutton bg-danger text-white"><i className="fas fa-trash"></i></a>
                                                        <a href=" " className="tablebutton bg-success text-white"><i className="fas fa-check"></i></a>
                                                        <a href=" " className="tablebutton color7 text-white"><i className="fas fa-close"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
                </div>
            </div>
           
        </div>
    )
}

export default Medicalcondition;