import React, { useEffect, useState } from "react";
import Footer from "../../footer/Footer";
import WOW from "wowjs";
import Sidenav from "../../../Pages/nav/Sidenav";
import Topnav from "../../../Pages/nav/Topnav";
import Student from "../../../api/student"; // Adjust the import path as needed

function Studentdetails() {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [erpnum, setErpnum] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    date_of_birth: "",
    gender: "",
    blood_group: "",
    religion: "",
    caste: "",
    fathers_name: "",
    mothers_name: "",
    fathers_occupation: "",
    mothers_occupation: "",
    fathers_qualification: "",
    mothers_qualification: "",
    id_mark1: "",
    id_mark2: "",
    aadhar_no: "",
    profile_picture: null,
    primary_mobile: "",
    alternate_mobile: "",
    father_mobile: "",
    mother_mobile: "",
    father_email: "",
    mother_email: "",
    permanent_address: "",
    permanent_city: "",
    permanent_state: "",
    permanent_zip: "",
    present_address: "",
    present_city: "",
    present_state: "",
    present_zip: "",
    erp_number: "",
    batch: "",
    fees_status: "",
  });

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const generateERPNumber = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");
    const randomNum = Math.floor(Math.random() * 1000) + 1;
    const num = `WLS${currentYear}SDAMDU${currentMonth}${randomNum}`;
    setErpnum(num);
    return `WLS${currentYear}SDAMDU${currentMonth}${randomNum}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const erp_number = generateERPNumber();
    const studentData = { ...formData, erp_number };

    try {
      const response = await Student.addStudent(studentData);
      // Add popup notification
      alert("Student created successfully!");

      // Clear form data
      setFormData({
        full_name: "",
        date_of_birth: "",
        gender: "",
        blood_group: "",
        religion: "",
        caste: "",
        fathers_name: "",
        mothers_name: "",
        fathers_occupation: "",
        mothers_occupation: "",
        fathers_qualification: "",
        mothers_qualification: "",
        id_mark1: "",
        id_mark2: "",
        aadhar_no: "",
        profile_picture: null,
        primary_mobile: "",
        alternate_mobile: "",
        father_mobile: "",
        mother_mobile: "",
        father_email: "",
        mother_email: "",
        permanent_address: "",
        permanent_city: "",
        permanent_state: "",
        permanent_zip: "",
        present_address: "",
        present_city: "",
        present_state: "",
        present_zip: "",
        erp_number: "",
        batch: "",
        fees_status: "",
      });
    } catch (error) {
      console.error("Error creating student:", error);
      // Handle error (e.g., show an error message)
      alert("Error creating student. Please try again.");
    }
  };

  return (
    <div className="nav-fixed">
      <Topnav toggleSidebar={toggleSidebar} />
      <div id="layoutSidenav">
        {isSidebarVisible && (
          <div>
            <Sidenav />
          </div>
        )}
        <div id="layoutSidenav_content">
          <div>
            <main>
              <div className="container-fluid p-3">
                <h1 className="mt-4 mb-2">Student Details</h1>
                <div className="row ms-2" id="uploaddata">
                  <form className="row g-4 mb-5" onSubmit={handleSubmit}>
                    <section className="wow fadeInLeft">
                      <div className="row mb-5">
                        <div className="iconbox color2 up10">
                          <div className="iconval wow shake">
                            <i className="fas fa-user-plus"></i>
                          </div>
                        </div>
                        <div className="heading">
                          <h4>Personal Information </h4>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row g-4 mt-5 mb-5">
                          <div className=" col-12">
                            <label className="form-label">
                              Full Name of the Student <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="full_name"
                              value={formData.full_name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className=" col-12 col-md-6">
                            <label className="form-label">
                              Date of Birth <span>*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="date_of_birth"
                              value={formData.date_of_birth}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className=" col-12 col-md-6">
                            <label className="form-label">
                              Gender<span>*</span>
                            </label>
                            <select
                              className="form-select"
                              name="gender"
                              value={formData.gender}
                              onChange={handleInputChange}
                              required
                            >
                              <option value=""> - Select - </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                          <div className=" col-12 col-md-6">
                            <label className="form-label">Blood Group</label>
                            <select
                              className="form-select"
                              name="blood_group"
                              value={formData.blood_group}
                              onChange={handleInputChange}
                            >
                              <option value=""> - Select - </option>
                              <option value="O+">O+</option>
                              <option value="O-">O-</option>
                              <option value="A+">A+</option>
                              <option value="A-">A-</option>
                              <option value="B+">B+</option>
                              <option value="B-">B-</option>
                              <option value="AB+">AB+</option>
                              <option value="AB-">AB-</option>
                              <option value="A1+">A1+</option>
                              <option value="A1-">A1-</option>
                            </select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">Religion</label>
                            <select
                              className="form-select"
                              name="religion"
                              value={formData.religion}
                              onChange={handleInputChange}
                              required
                            >
                              <option value=""> - Select - </option>
                              <option value="hindu">Hindu</option>
                              <option value="christian">Christian</option>
                              <option value="muslim">Muslim</option>
                              <option value="none">None</option>
                              <option value="other">Other</option>
                            </select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">Caste</label>
                            <select
                              className="form-select"
                              name="caste"
                              value={formData.caste}
                              onChange={handleInputChange}
                              required
                            >
                              <option value=""> - Select - </option>
                              <option value="general">General</option>
                              <option value="BC">BC</option>
                              <option value="MBC">MBC</option>
                              <option value="SC">SC</option>
                              <option value="ST">ST</option>
                              <option value="FC">FC</option>
                              <option value="OC">OC</option>
                              <option value="EWS">EWS</option>
                              <option value="none">None</option>
                              <option value="others">Others</option>
                            </select>
                          </div>
                          <div className=" col-12 col-md-6">
                            <label className="form-label">
                              Father's name <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="fathers_name"
                              value={formData.fathers_name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              Mother's name <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="mothers_name"
                              value={formData.mothers_name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className=" col-12 col-md-6">
                            <label className="form-label">
                              Father's Occupation <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="fathers_occupation"
                              value={formData.fathers_occupation}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              Mother's Occupation <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="mothers_occupation"
                              value={formData.mothers_occupation}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className=" col-12 col-md-6">
                            <label className="form-label">
                              Father's Qualification <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="fathers_qualification"
                              value={formData.fathers_qualification}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              Mother's Qualification <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="mothers_qualification"
                              value={formData.mothers_qualification}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className=" col-12 col-md-6">
                            <label className="form-label">
                              Identification Mark 1 <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="id_mark1"
                              value={formData.id_mark1}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              Identification Mark 2 <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="id_mark2"
                              value={formData.id_mark2}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className=" col-12">
                            <label className="form-label">Aadhar Number</label>
                            <input
                              type="number"
                              className="form-control"
                              name="aadhar_no"
                              value={formData.aadhar_no}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <label className="form-label">
                              Profile Picture
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="profile_picture"
                              onChange={handleFileChange}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="wow fadeInRight">
                      <div className="row mb-5">
                        <div className="iconbox color3 up10">
                          <div className="iconval wow shake">
                            <i className="fas fa-phone-square-alt"></i>
                          </div>
                        </div>
                        <div className="heading">
                          <h4>Contact Details</h4>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row g-4 mt-5 mb-5">
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              Primary Mobile Number<span>*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="primary_mobile"
                              value={formData.primary_mobile}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              Alternate Mobile Number
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="alternate_mobile"
                              value={formData.alternate_mobile}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              Father's Mobile Number
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="father_mobile"
                              value={formData.father_mobile}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              Mother's Mobile Number
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="mother_mobile"
                              value={formData.mother_mobile}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              Father's E-mail Address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="father_email"
                              value={formData.father_email}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              Mother's E-mail Address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="mother_email"
                              value={formData.mother_email}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-12">
                            <label className="form-label">
                              Permanent Address <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="permanent_address"
                              value={formData.permanent_address}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              City <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="permanent_city"
                              value={formData.permanent_city}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              State <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="permanent_state"
                              value={formData.permanent_state}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              ZIP Code <span>*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="permanent_zip"
                              value={formData.permanent_zip}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <label className="form-label">
                              Corresponding Address <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="present_address"
                              value={formData.present_address}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              City <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="present_city"
                              value={formData.present_city}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              State <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="present_state"
                              value={formData.present_state}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="form-label">
                              ZIP Code <span>*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="present_zip"
                              value={formData.present_zip}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="wow fadeInLeft">
                      <div className="row mb-5">
                        <div className="iconbox color4 up10">
                          <div className="iconval wow shake">
                            <i className="fas fa-briefcase"></i>
                          </div>
                        </div>
                        <div className="heading">
                          <h4>Office Details </h4>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row g-4 mt-5 mb-5">
                          <div className="col-12 col-md-6">
                            <label className="form-label">ERP Number</label>
                            <input
                              type="text"
                              className="form-control"
                              name="erp_number"
                              value={erpnum}
                              readOnly
                            />
                          </div>
                          <div className=" col-12 col-md-6">
                            <label className="form-label">
                              Batch <span>*</span>
                            </label>
                            <select
                              className="form-select"
                              name="batch"
                              value={formData.batch}
                              onChange={handleInputChange}
                            >
                              <option value=""> - Select - </option>
                              <option value="2029-2030">2029-2030</option>
                              <option value="2028-2029">2028-2029</option>
                              <option value="2027-2028">2027-2028</option>
                              <option value="2026-2027">2026-2027</option>
                              <option value="2025-2026">2025-2026</option>
                              <option value="2024-2025">2024-2025</option>
                              <option value="2023-2024">2023-2024</option>
                              <option value="2022-2023">2022-2023</option>
                            </select>
                          </div>
                          <div className=" col-12 col-md-6">
                            <label className="form-label">
                              Fees <span>*</span>
                            </label>
                            <select
                              className="form-select"
                              name="fees_status"
                              value={formData.fees_status}
                              onChange={handleInputChange}
                            >
                              <option selected> - Select - </option>
                              <option value="Paid">Paid</option>
                              <option value="Unpaid">Unpaid</option>
                            </select>
                          </div>
                          <div className="col-12">
                            <button className="btn btn-theme2" type="submit">
                              Create Student Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </form>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Studentdetails;
