  import React, { useState, useEffect } from "react";
  import Sidenav from "../../../Pages/nav/Sidenav";
  import Footer from "../../footer/Footer";
  import Topnav from "../../../Pages/nav/Topnav";
  import createPass from "../../../api/Administration/visitor/visitor";
  import Classroom from "../../../api/classroom";
  import { retrieveAuthToken } from "../../../api/authentication";

  function Visitors() {
    const [visitorLog, setVisitorLog] = useState([]);
    const [visitorDetails, setVisitorDetails] = useState({
      persontoMeetType: "",
      persontoMeetName: "",
      classname: "",
      visitorName: "",
      visitorType: "",
      reason: "",
      mobile: "",
      entryTime: "",
      exitTime: "",
      updatedAt: new Date().toISOString(),
    });
    const [classOptions, setClassOptions] = useState([]);
    const [studentOptions, setStudentOptions] = useState([]);
    const token = retrieveAuthToken();

    useEffect(() => {
      const fetchVisitors = async () => {
        try {
          const response = await createPass.getVisitros({
            headers: {
              Authorization: token,
              schoolId: process.env.REACT_APP_SCHOOL_ID,
            },
          });
          setVisitorLog(response);
        } catch (error) {
          console.error("Error fetching visitors:", error);
        }
      };

      fetchVisitors();
    }, []);

    useEffect(() => {
      // Fetch class options from API
      const fetchClassOptions = async () => {
        try {
          const classes = await Classroom.getClassroom();
          setClassOptions(classes);
        } catch (error) {
          console.error("Error fetching class options:", error);
        }
      };

      fetchClassOptions();
    }, [token]);

    const handleChange = async (e) => {
      const { name, value } = e.target;

      if (name === "classname") {
        // Fetch students based on class ID
        if (value !== "") {
          try {
            const students = await createPass.getclassroomid(value);
            setStudentOptions(students);
          } catch (error) {
            console.error("Error fetching students:", error);
          }
        } else {
          setStudentOptions([]); // Clear student options if no class selected
        }
      }

      setVisitorDetails({ ...visitorDetails, [name]: value });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const updatedDetails = {
          ...visitorDetails,
          entryTime: new Date(visitorDetails.entryTime).toISOString(),
          exitTime: new Date(visitorDetails.exitTime).toISOString(),
        };

        const postresponse = await createPass.addVisitors(updatedDetails);

        // Update the visitor log state
        setVisitorLog([...visitorLog, postresponse]);
      } catch (error) {
        console.error("Error adding visitor:", error);
      }
    };

    return (
      <div className="nav-fixed">
        <Topnav />
        <div id="layoutSidenav">
          <div>
            <Sidenav />
          </div>
          <div id="layoutSidenav_content">
            <div>
              <main>
                <div className="container-fluid p-3">
                  <h1 className="mt-4 mb-2">Visitor Entry</h1>
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <section>
                        <div className="row mt-5">
                          <h5 className="mt-5">
                            <u>Enter Visitor Details</u>
                          </h5>
                          <form className="row g-3" onSubmit={handleSubmit}>
                            <div className="col-12">
                              <label className="form-label">
                                Person to Meet <span>*</span>
                              </label>
                              <select
                                className="form-select"
                                name="persontoMeetType"
                                value={visitorDetails.persontoMeetType}
                                onChange={handleChange}
                                required
                              >
                                <option value=""> - Select - </option>
                                <option value="Student">Student</option>
                                <option value="Staff">Staff</option>
                              </select>
                            </div>
                            <div className="col-12">
                              <label className="form-label">
                                Visitor Type <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="persontoMeetName"
                                value={visitorDetails.persontoMeetName}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12">
                              <label className="form-label">Class</label>
                              <select
                                className="form-select"
                                name="classname"
                                value={visitorDetails.classname}
                                onChange={handleChange}
                              >
                                <option value=""> - Select - </option>
                                {classOptions.map((option, index) => (
                                  <option key={index} value={option.id}>
                                    {option.class}-{option.section}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {visitorDetails.classname && (
                              <div className="col-12">
                                <label className="form-label">
                                  Meeting With <span>*</span>
                                </label>
                                <select
                                  className="form-select"
                                  name="visitorName"
                                  value={visitorDetails.visitorName}
                                  onChange={handleChange}
                                  required
                                >
                                  <option value=""> - Select - </option>
                                  {studentOptions.map((student, index) => (
                                    <option key={index} value={student.id}>
                                      {student.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                            <div className="col-12">
                              <label className="form-label">Visitor Type</label>
                              <select
                                className="form-select"
                                name="visitorType"
                                value={visitorDetails.visitorType}
                                onChange={handleChange}
                              >
                                <option value=""> - Select - </option>
                                <option value="Parent">Parent</option>
                                <option value="Relative">Relative</option>
                                <option value="Friend">Friend</option>
                                <option value="Guest">Guest</option>
                                <option value="Others">Others</option>
                              </select>
                            </div>
                            <div className="col-12">
                              <label className="form-label">Reason</label>
                              <input
                                type="text"
                                className="form-control"
                                name="reason"
                                value={visitorDetails.reason}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12">
                              <label className="form-label">
                                Mobile <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="mobile"
                                value={visitorDetails.mobile}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12">
                              <label className="form-label">
                                Entry Time <span>*</span>
                              </label>
                              <input
                                type="datetime-local"
                                className="form-control"
                                name="entryTime"
                                value={visitorDetails.entryTime}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12">
                              <label className="form-label">
                                Exit Time <span>*</span>
                              </label>
                              <input
                                type="datetime-local"
                                className="form-control"
                                name="exitTime"
                                value={visitorDetails.exitTime}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12">
                              <button className="btn btn-theme2" type="submit">
                                Create Pass
                              </button>
                            </div>
                          </form>
                        </div>
                      </section>
                    </div>
                    <div className="col-12 col-md-8">
                      <section>
                        <div className="row mt-5 mb-5">
                          <div className="iconbox color2 up10">
                            <div className="iconval wow shake">
                              <i className="fas fa-user-group"></i>
                            </div>
                          </div>
                          <div className="heading">
                            <h4>Visitor Log</h4>
                          </div>
                        </div>
                        <div className="row">
                          <table
                            id=""
                            className="display"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>Id</th>
                                <th>Visitor</th>
                                <th>Type</th>
                                <th>Reason</th>
                                <th>Entry Time</th>
                                <th>Exit Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              {visitorLog.map((visitor, index) => (
                                <tr key={index}>
                                  <td>{visitor.visitor_id}</td>
                                  <td>{visitor.visitor_name}</td>
                                  <td>{visitor.type}</td>
                                  <td>{visitor.reason}</td>
                                  <td>{visitor.entry_time}</td>
                                  <td>{visitor.exit_time}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </main>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }

  export default Visitors;

