import React from "react";

function ImageComponent(props) {
  const { imageUrl } = props;

  return (
    <img src={imageUrl} className="card-img-top img-fluid" alt="profile" />
  );
}

export default ImageComponent;
